import axios from "axios";
import Event from "module/event/Event";

// DaoNotice class  
class DaoNotice extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);

        this.uploadUrl = process.env.REACT_APP_BASICAPI+'upload/'+process.env.REACT_APP_NOTICEAPI; // api 전달 url
    }

    async uploadFile(id, data, headers) {
        //upload media
        const url = `${this.uploadUrl}/`+id; 
        const method = "POST";

        return await this.sendAxios(url, method, data, '',headers); //make option
    }

    async findAll(params){

        //전체리스트 가져오기
        const url = `${this.baseUrl}/all`+params;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async findCateAll(cate,params){
        //카테고리 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/`+cate;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'' ,params);//make option
    }

    async findStateAll(state,params){ 
        //상태 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/state/`+state;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'' ,params);//make option
    }

}

export default DaoNotice;