/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


* create event list  

*/
import React from "react";
import axios from "axios";
import { useParams } from 'react-router';
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatePicker } from 'reactstrap-date-picker'

import {escapeHtml,unescapeHtml} from "../../lib/global_function";

// control session
import Session from "module/session/Session";
// mobule event 
import Event from "module/event/Event";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";


function EventCreate() {

  const sessionInfo = Session.getSession();
  const [value, setValue] = useState(new Date().toISOString())
  const [fmtValue, setFmtValue] = useState(null)
  const [type, setType] = useState('등록');
  const [data, setData] = useState([]);
  const [imgSrc, setImgSrc] = useState('');

  // check get param about eventId
  const queryParameters = new URLSearchParams(window.location.search)
  const eventId = queryParameters.get("eventId")

  //Event class
  let event = new Event(process.env.REACT_APP_EVENTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {

    if (eventId) {
      setType('수정');
      getData();
    }

  }, []);

  //등록 혹은 수정
  const Submit = async () => {

    var title = document.getElementById("title").value;
    var content = escapeHtml(document.getElementById("content").value);

    var data = {
      "title": title,
      "content": content
    };

    if (eventId != '' && eventId != null) {
      data = {
        "title": title,
        "content": content,
        "eventId": eventId,
      };
    }

    const result = eventId != '' && eventId != null ? await event.update(data) : await event.create(data);

    console.log(result);

    if (result.data.code == 200) {
      // alert('등록완료');
      window.location.href = '/admin/event';
    } else {
      alert('등록실패');
    }

  }

  const customUploadAdapter = (loader) => { // (2)
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(async (file) => {
            // data.append("name", file.name);
            data.append("media", file);

            console.log(file);

            var headers = {
              'Content-Type': 'application/x-www-form-urlencoded'
            }

            var result = await event.uploadFile(eventId, data, headers);

            console.log(result);

            if (result.data.codeMsg == 'SUCCESS') {
              // 이미지 업로드 완료
              setImgSrc(result.data.data);

              resolve({
                default: `${result.data.data}`
              });
            } else {
              alert('이미지 업로드에 실패하였습니다. 개발자에게 문의해 주세요.');
            }

          })
        })
      }
    }
  }

  function uploadPlugin(editor) { // (3)
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    }
  }



  const getData = async () => {
    var result = await event.findOne('eventId=' + eventId)
    await setData(result.data.data)
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">이벤트 {type}</h3>
              </CardHeader>
              <CardBody>
                <Col className="pr-md-1 titleAdmin" md="6">
                  <FormGroup>
                    <label>제목</label>
                    <Input
                      name="title"
                      defaultValue={data.title}
                      placeholder="Title"
                      type="text"
                      id="title"
                      className="fs-5"
                    />
                  </FormGroup>
                </Col>
                <Row>
                  {/* <Col md="8">
                      <FormGroup>
                        <label>이벤트 시작일</label>
                        <DatePicker
                          id="s_date"
                          className="fs-5"
                          value={value}
                          onChange={(v, f) => handleChange(v, f)} />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <FormGroup>
                        <label>이벤트 마감일</label>
                        <DatePicker
                          id="e_date"
                          value={value}
                          className="fs-5"
                          onChange={(v, f) => handleChange(v, f)} />
                      </FormGroup>
                    </Col> */}
                </Row>
                <Row>
                  <Input type="hidden" name="content" id="content" value={data.content}>
                  </Input>
                  <Col md="8">
                    <CKEditor
                      id="content"
                      editor={ClassicEditor}
                      config={{ // (4)
                        extraPlugins: [uploadPlugin]
                      }}
                      data={unescapeHtml(data.content)}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);

                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        document.getElementById('content').value = data;
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  {type}하기
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EventCreate;
