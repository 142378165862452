/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

// control session
import Session from "module/session/Session";
// module settlement
import Settlement from "module/settlement/Settlement";
// module project
import Project from "module/project/Project";

import Platform from "module/platform/Platform";

import Funding from "module/funding/Funding";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function SettlementCreate() {
  const [data, setData] = useState({});
  const [projectData, setProjectData] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [taxInvoice, setTaxInvoice] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id") ?? "";

  const sessionInfo = Session.getSession();

  var pageSize = 5000;
  var page = 1;

  const state = {
    O1: "정산신청",
    CD: "정산취소",
    HD: "정산보류",
    OD: "정산완료"
  };

  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //contestant class
  let project = new Project(process.env.REACT_APP_PROJECTAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //funding class
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //settlement class
  let settlement = new Settlement(process.env.REACT_APP_SETTLEMENTAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  useEffect(() => {
    if (id) {
      getData();
      getProjectData();
      getPlatformData();
      getFundingData();
    }
  }, [id]);

  useEffect(() => {
    setSelectedState(data.status ?? "");
  }, [data]);

  const getData = async () => {
    var result = await settlement.findOne("id=" + id);
    setData(result.data.data);
    setTaxInvoice(result.data.data.taxInvoice);
  };

  const getPlatformData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await platform.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].fundingIncomePlatformId) {
          result_arr.push({
            value: result.data.data[i].fundingIncomePlatformId,
            label: result.data.data[i].name
          });
        }
      }
    }

    await setPlatformData(result_arr);
  };

  const getProjectData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await project.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].contestantId) {
          result_arr.push({
            value: result.data.data[i].contestantId,
            label: result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setProjectData(result_arr);
  };

  const getFundingData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await funding.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].contestantId) {
          result_arr.push({
            value: result.data.data[i].contestantId,
            label: result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setFundingData(result_arr);
  };

  const handleSubmit = async () => {
    const updatedData = {
      id: id,
      status: selectedState,
      taxInvoice: taxInvoice,
      comment: document.getElementById("comment").value
    };

    const result = await settlement.update(updatedData);

    if (result.data.code === 200) {
      window.location.href = "/admin/settlementList";
    } else {
      alert("업데이트 실패");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">정산신청내역</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>이메일</label>
                        <Input
                          type="text"
                          value={data.user?.email ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>닉네임</label>
                        <Input
                          type="text"
                          value={data.user?.nickName ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>정산신청금액</label>
                        <Input
                          type="text"
                          value={data.amount ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>상태</label>
                        <Input
                          type="select"
                          id="status"
                          value={selectedState}
                          onChange={(e) => setSelectedState(e.target.value)}
                          className="mt-3 text-white"
                        >
                          {Object.entries(state).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>세금계산서 발행 여부</label>
                        <div className="ml-3">
                          <label>
                            <Input
                              type="radio"
                              name="taxInvoice"
                              value="true"
                              checked={taxInvoice === true}
                              onChange={() => setTaxInvoice(true)}
                            />
                            발행
                          </label>
                          <label className="ml-5">
                            <Input
                              type="radio"
                              name="taxInvoice"
                              value="false"
                              checked={taxInvoice === false}
                              onChange={() => setTaxInvoice(false)}
                            />
                            미발행
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>생성일</label>
                        <Input
                          type="text"
                          value={data.createdAt ?? ""}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>수정일</label>
                        <Input
                          type="text"
                          value={data.editedAt ?? "수정되지 않음"}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>정산승인일</label>
                        <Input
                          type="text"
                          value={data.confirmDt ?? "확인되지 않음"}
                          readOnly
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>코멘트</label>
                        <Input
                          type="text"
                          id="comment"
                          defaultValue={data.comment ?? ""}
                          className="mt-3 text-white"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  상태 업데이트
                </Button>
                <Button
                  className="btn-fill"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    window.location.href = "/admin/settlementList";
                  }}
                >
                  목록으로 돌아가기
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SettlementCreate;
