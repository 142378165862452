/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NICE_BANK_CODE } from "../../lib/global_variable";

// control session
import Session from "module/session/Session";
// Platform class 생성
import Platform from "module/platform/Platform";
// Funding class 생성
import Funding from "module/funding/Funding";
// User class 생성
import User from "module/user/User";

import Paginations from "../../components/Pagination";

// reactstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  PaginationItem,
  PaginationLink,
  PaginationComponent,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

function PlatformList() {
  const [list, setList] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [reload, setReload] = useState(false);
  const sessionInfo = Session.getSession();
  const [platformListCnt, setPlatformListCnt] = useState(0); //all platform list
  var pageSize = 100;

  //이미지 확장자 여부 확인
  var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const offset = (page - 1) * pageSize; // 시작점과 끝점을 구하는 offset

  const state = {
    O1: "신청",
    CD: "취소",
    HD: "승인보류",
    OD: "승인완료"
  };

  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");
  // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const formattedDateTime = now.toISOString().replace("Z", "");

  useEffect(() => {
    loadList(page);
    setPagination();
  }, []);

  useEffect(() => {
    loadList(page);
  }, [page]);

  useEffect(() => {
    setPagination();
  }, [list]);

  const loadList = async (page) => {
    var param = "?pageSize=" + pageSize + "&currentPage=" + (page - 1);

    if (searchType != "" && searchKeyword != "") {
      switch (searchType) {
        case "name":
          param += "&name=" + searchKeyword;
          break;
      }
    }

    var result = await platform.findAll(param);

    setPage(page);

    if (result.data.data && result.data.data.length > 0) {
      var platformDatas = result.data.data;

      setList(platformDatas);
      setPlatformListCnt(platformDatas.length);

      setPagination();
    } else {
      setList([]);
      setPlatformListCnt(platformDatas.length);
    }
  };

  const setPagination = () => {
    setStartPage(page);
    setEndPage(
      platformListCnt % pageSize > 0
        ? parseInt(platformListCnt / pageSize) + 1
        : parseInt(platformListCnt / pageSize)
    );

    if (page == 1) {
      setStartPage(1);
    }
  };

  //상태값 업데이트 하기
  const updateState = async () => {
    var updateList = document.querySelectorAll(".platformId");

    console.log(updateList);

    if (updateList.length < 1) {
      alert("상태값을 수정할 항목을 체크해주세요.");
      return false;
    } else {
      updateList.forEach(async (element) => {
        if (
          element.checked &&
          document.getElementById("changeState").value != ""
        ) {
          var created_at = document.getElementById(
            "createdAt_" + element.value
          ).value;

          if (created_at.length == 22) {
            created_at = created_at + "1";
          }

          var result = await platform.update("?platformId=" + element.value, {
            platformId: element.value,
            status: document.getElementById("changeState").value
          });

          console.log(result);
        }
      });

      alert("상태값을 변경하였습니다");
      window.location.reload();
    }
  };

  // 체크 리스트 삭제 처리
  const deleteList = async () => {
    const deleteList = Array.from(document.querySelectorAll(".platformId"));
    const checkedItems = deleteList.filter((element) => element.checked);

    if (checkedItems.length < 1) {
        alert("삭제할 항목을 체크해주세요.");
        return false;
      } else {
        for (const element of checkedItems) {
          // element가 input 요소가 맞는지 확인하고, value 값을 올바르게 가져옵니다.
          const fundingIncomePlatformId = element.value;
          
          if (!fundingIncomePlatformId) {
            console.error("삭제할 ID가 없습니다:", element);
            continue;
          }
  
          // 삭제 요청을 보냅니다.
          const result = await platform.remove("?fundingIncomePlatformId="+fundingIncomePlatformId);
          console.log(result);
        }
  
        alert("리스트를 삭제하였습니다.");
        window.location.reload();
      }
    };

  // 정렬기능추가
  const setOrderType = async (e) => {
    if (searchOrder == "") {
      setSearchOrder("asc");
    } else {
      setSearchOrder("");
    }

    loadList(page);
  };

  const handleChange = async (e) => {
    if (e.target.name == "searchStatus") {
      setSearchStatus(e.target.value);
    } else if (e.target.name == "searchType") {
      setSearchType(e.target.value);
    } else if (e.target.name == "searchKeyword") {
      setSearchKeyword(e.target.value);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <div className="d-inline-flex">
                  <FormGroup
                    check
                    style={{ display: "inline-block", marginRight: "5px" }}
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <Input
                          type="select"
                          name="searchType"
                          id="searchType"
                          value={searchType}
                          onChange={handleChange}
                        >
                          <option value="">검색타입</option>
                          <option value="name">플랫폼명</option>
                        </Input>
                      </div>
                      <input
                        type="text"
                        id="searchKeyword"
                        name="searchKeyword"
                        value={searchKeyword}
                        class="form-control"
                        aria-label="검색어를 입력해주세요."
                        onChange={handleChange}
                      ></input>
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          loadList(page);
                        }}
                      >
                        검색하기
                      </Button>
                    </div>
                  </FormGroup>
                </div>
                <Link to="/admin/platformCreate">
                  <Button style={{ float: "right" }}>등록</Button>
                </Link>
                <Button
                  className="btn btn-danger"
                  style={{ float: "right" }}
                  onClick={deleteList}
                >
                  삭제하기
                </Button>
              </CardHeader>
              <CardBody>
                <div
                  className="table-full-width table-responsive"
                  style={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                  <Table>
                    <colgroup>
                      <col width="5%" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <th></th>
                      <th>플랫폼명</th>
                      <th>등록일</th>
                      <th></th>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((platformData, ind) => {
                          return (
                            <tr>
                              <td className="w-5">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      value={
                                        platformData.fundingIncomePlatformId ??
                                        ""
                                      }
                                      type="checkbox"
                                      className="platformId"
                                    />
                                    <span className="form-check-sign">
                                      <span className="check" />
                                    </span>
                                  </Label>
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {platformData.name
                                    ? platformData.name
                                    : "플랫폼명"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {platformData.createdAt
                                    ? platformData.createdAt
                                    : "0000-00-00 00:00:00"}
                                </span>
                              </td>
                              <td className="td-actions text-right">
                                <Link
                                  to={
                                    `/admin/platformCreate?platformId=` +
                                    platformData.fundingIncomePlatformId
                                  }
                                >
                                  <Button
                                    color="link"
                                    id="tooltip457194718"
                                    title=""
                                    type="button"
                                  >
                                    <i className="tim-icons icon-pencil" />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div
            className="pagination"
            id="pagination"
            style={{
              position: "absolute",
              left: "50%",
              bottom: "0%",
              tranform: "translate(-50%,0)"
            }}
          >
            <Paginations
              limit={pageSize}
              page={page}
              totalPosts={platformListCnt}
              setPage={setPage}
            />
          </div>
        </Row>
      </div>
    </>
  );
}

export default PlatformList;
