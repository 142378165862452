/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from "react-select";

// control session
import Session from "module/session/Session";
// module budget 
import Budget from "module/budget/Budget";
// module project 
import Project from "module/project/Project";

import Platform from "module/platform/Platform";

import Funding from "module/funding/Funding";



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function BudgetCreate() {

  const [data, setData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [fundingData, setFundingData] = useState([]);

  var pageSize = 5000;
  var page = 1;

  const queryParameters = new URLSearchParams(window.location.search)
  const detailId = queryParameters.get("detailId") ?? ''

  const sessionInfo = Session.getSession();


  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //contestant class
  let project = new Project(process.env.REACT_APP_PROJECTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //funding class
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //budget class
  let budget = new Budget(process.env.REACT_APP_BUDGETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {
    if (detailId != '' && detailId != null) {
      getData();
    }
    getProjectData();
    getPlatformData();
    getFundingData();
  }, [])

  const getData = async () => {
    var result = await budget.findOne('detailId=' + detailId)
    await setData(result.data.data)
  }

  const getPlatformData = async (where) => {
    var param = '?pageSize=5000&currentPage=0';
    var result = await platform.findAll(param)

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].fundingIncomePlatformId) {
          result_arr.push({
            "value": result.data.data[i].fundingIncomePlatformId,
            "label": result.data.data[i].name
          });
        }
      }
    }

    await setPlatformData(result_arr)
  }


  const getProjectData = async (where) => {
    var param = '?pageSize=5000&currentPage=0';
    var result = await project.findAll(param)

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].pollingResultId) {
          result_arr.push({
            "value": result.data.data[i].pollingResultId,
            "label": result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setProjectData(result_arr)
  }

  const getFundingData = async (where) => {
    var param = '?pageSize=5000&currentPage=0';
    var result = await funding.findAll(param)

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].pollingResultId) {
          result_arr.push({
            "value": result.data.data[i].pollingResultId,
            "label": result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setFundingData(result_arr)
  }


  //등록 혹은 수정
  const Submit = async () => {
    // 티켓 금액
    var platformId = document.getElementById("platformId").value ?? '';
    // 티켓 아이디 
    var pollingResultId = document.getElementById("pollingResultId").value ?? '';
    // 티켓 아이디 
    var shareAmount = document.getElementById("shareAmount").value ?? '';
    // 티켓 아이디 
    var comment = document.getElementById("comment").value ?? '';
    // 년도
    var year = document.getElementById('year').value ?? '';
    // 월
    var month = document.getElementById('month').value ?? '';

    // 입력 필드가 비어 있는지 확인
    if (!platformId) {
      alert("플랫폼 정보를 입력하세요.");
      return;
    }
    if (!pollingResultId) {
      alert("티켓 아이디를 입력하세요.");
      return;
    }
    if (!shareAmount) {
      alert("티켓 금액을 입력하세요.");
      return;
    }
    if (!comment) {
      alert("코멘트를 입력하세요.");
      return;
    }
    if (!year) {
      alert("년도를 입력하세요.");
      return;
    }
    if (!month) {
      alert("월을 입력하세요.");
      return;
    }

    var data = {
      "year": year,
      "month": month,
      "platformId": platformId,
      "pollingResultId": pollingResultId,
      "shareAmount": shareAmount,
      "comment": comment,
    }

    if (detailId && detailId != '' && detailId != null) {
      data.fundingIncomeDetailId = detailId
    }

    const result = detailId != '' && detailId != null ? await budget.update(data) : await budget.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/budgetList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>

          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {detailId != null ? <h3 className="Category">플랫폼 월별 정산내역 수정</h3> : <h3 className="Category">플랫폼 월별 정산내역 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col>
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                      <label>
                        플랫폼 정보
                        <Input type="select" name="platformId" id="platformId" className="mt-3">
                          {platformData && platformData.map((prop, key) => {
                            var selected = '';
                            if (prop.value == data.PlatformId) {
                              selected = 'selected'
                            }
                            return <option value={prop ? prop.value : ''} selected={selected}>{prop.label}</option>
                          })}
                        </Input>
                      </label>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                      <label>
                        년(year)
                        <Input type="number" name="year" id="year" className="mt-3" defaultValue={data.year || ''} maxLength="4"/>
                      </label>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                      <label>
                        월(month)
                        <Input type="number" name="month" id="month" className="mt-3" defaultValue={data.month || ''} max="12" min="1"/>
                      </label>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                      <label>
                      입금 대상 작품
                        <Input type="select" name="pollingResultId" id="pollingResultId" className="mt-3">
                          {fundingData && fundingData.map((prop, key) => {
                            var selected = '';
                            if (prop.value == data.pollingResultId) {
                              selected = 'selected'
                            }
                            return <option value={prop ? prop.value : ''} selected={selected}>{prop.label ?? '작품명'}</option>
                          })}
                        </Input>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>해당 작품의 입금건 총액</label>
                      <Input
                        name="shareAmount"
                        defaultValue={data.shareAmount ?? ''}
                        placeholder="해당 작품의 입금건 총액"
                        type="text"
                        className="fs-5"
                        id="shareAmount"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>별도 추가 기입사항</label>
                      <Input
                        name="comment"
                        defaultValue={data.comment ?? ''}
                        placeholder="별도 추가 기입사항"
                        type="text"
                        className="fs-5"
                        id="comment"
                      />
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  등록
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BudgetCreate;
