import axios from "axios";
import Event from "module/event/Event";

// PollingResult class  
class PollingResult extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

    async findAll(params){
        //전체리스트 가져오기
        const url = `${this.baseUrl}/all`+params;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async findCateAll(cate,params){
        //카테고리 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/`+cate;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'' ,params);//make option
    }

    async findStateAll(state,params){ 
        //상태 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/state/`+state;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'' ,params);//make option
    }

    async createAuto(category,params){ 
        //자동으로 투표결과 등록 시키기
        const url = `${this.baseUrl}/makePollingResult?category=`+category;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'',params);//make option
    }

}

export default PollingResult;