/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// module ticket
import Ticket from "module/ticket/Ticket";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function TicketCreate() {

  const [data, setData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState('');
  const [contestantId, setContestantId] = useState('');

  var pageSize = 1000;
  var page = 1;

  const queryParameters = new URLSearchParams(window.location.search)
  const ticketId = queryParameters.get("ticketId") ?? ''

  const sessionInfo = Session.getSession();


  //ticket class
  let ticket = new Ticket(process.env.REACT_APP_TICKETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });


  useEffect(() => {
    if (ticketId != '' && ticketId != null) {
      getData();
    }
  }, [])

  const getData = async () => {
    var result = await ticket.findOne('ticketId=' + ticketId)
    await setData(result.data.data)
  }


  //등록 혹은 수정
  const Submit = async () => {

    // 티켓 금액
    var ticketAmount = document.getElementById("ticketAmount").value;
    // 티켓 한정 수량
    var ticketMaxCnt = document.getElementById("ticketMaxCnt").value;
    // 사용 가능일
    var startAt = document.getElementById("startAt").value ?? '';
    // 사용 마감일
    var endAt = document.getElementById("endAt").value ?? '';
    // 구매갯수
    var useCnt = document.getElementById("useCnt").value ?? '';
    // 티켓 유형
    var type = document.getElementById("type").value ?? '';
    // 티켓 명칭
    var ticketName = document.getElementById("ticketName").value ?? '';

    var data = {
      ticketAmount: ticketAmount,
      ticketMaxCnt: ticketMaxCnt,
      startAt: startAt,
      endAt: endAt,
      useCnt: useCnt,
      type: type,
      ticketName : ticketName
    };


    if (ticketId && ticketId != '' && ticketId != null) {
      data.ticketId = ticketId
    }

    const result = ticketId != '' && ticketId != null ? await ticket.update(data) : await ticket.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/ticketList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {ticketId != '' && ticketId != null ? <h3 className="Category">티켓 수정</h3> : <h3 className="Category">티켓 등록</h3>}
                {console.log(data)}
              </CardHeader>
              <CardBody>
                <Form>
                <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓명</label>
                      <Input
                        name="ticketName"
                        defaultValue={data.ticketName ?? ''}
                        placeholder="티켓명을 입력해 주세요."
                        type="text"
                        className="fs-5"
                        id="ticketName"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓금액</label>
                      <Input
                        name="ticketAmount"
                        defaultValue={data.ticketAmount ?? 0}
                        value={data.ticketAmount ?? 0}
                        placeholder="티켓 금액을 입력해 주세요."
                        type="text"
                        className="fs-5"
                        id="ticketAmount"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓 한정 갯수</label>
                      <Input
                        name="ticketMaxCnt"
                        defaultValue={data.ticketMaxCnt ?? 0}
                        value={data.ticketMaxCnt ?? 0}
                        placeholder="티켓 한정 수량을 지정할 경우에만 입력해 주세요."
                        type="number"
                        className="fs-5"
                        id="ticketMaxCnt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>사용가능 시작일</label>
                      <Input
                        name="startAt"
                        defaultValue={data.startAt ?? ''}
                        placeholder="startAt"
                        type="date"
                        className="fs-5"
                        id="startAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>사용가능 마감일</label>
                      <Input
                        name="endAt"
                        defaultValue={data.endAt ?? ''}
                        placeholder="endAt"
                        type="date"
                        className="fs-5"
                        id="endAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>구매된 티켓 갯수</label>
                      <input
                        name="useCnt"
                        type="number"
                        defaultValue={data.useCnt ?? 0}
                        value={data.useCnt ?? 0}
                        className="fs-5 w-100"
                        id="useCnt"
                        readOnly="true" />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓 유형</label>
                      <Input type="select" name="type" id="type" className="mt-3">
                        <option value="">티켓 유형을 선택해 주세요</option>
                        {data.type && data.type == "MYMCARD" ? <option value="MYMCARD" selected>밈카드</option> :  <option value="MYMCARD">밈카드</option> } 
                        {data.type && data.type == "CONTENT" ? <option value="CONTENT" selected>콘텐츠 제작</option> :  <option value="CONTENT">콘텐츠 제작</option> } 
                      </Input>
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TicketCreate;
