/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// module polling 
import PollingResult from "module/polling/PollingResult";
// module funding 
import Funding from "module/funding/Funding";
// module ticket 
import Ticket from "module/ticket/Ticket";
// module project
import Project from "module/project/Project";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function FundingCreate() {

  const [data, setData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [detailImgSrc, setDetailImgSrc] = useState(''); // 상품설명 상세 이미지(웹) 
  const [detailMobileImgSrc, setDetailMobileImgSrc] = useState(''); // 상품설명 상세 이미지(모바일) 
  const [introImgSrc, setIntroImgSrc] = useState(''); // 소개 상세 이미지(웹) 
  const [introImgSrc1, setIntroImgSrc1] = useState(''); // 소개 상세 이미지(웹) 1
  const [introImgSrc2, setIntroImgSrc2] = useState(''); // 소개 상세 이미지(웹) 2
  const [introImgSrc3, setIntroImgSrc3] = useState(''); // 소개 상세 이미지(웹) 3
  const [introImgSrc4, setIntroImgSrc4] = useState(''); // 소개 상세 이미지(웹) 4
  const [introMobileImgSrc, setIntroMobileImgSrc] = useState(''); // 소개 상세 이미지(모바일) 
  const [introMobileImgSrc1, setIntroMobileImgSrc1] = useState(''); // 소개 상세 이미지(모바일) 1
  const [introMobileImgSrc2, setIntroMobileImgSrc2] = useState(''); // 소개 상세 이미지(모바일) 2
  const [introMobileImgSrc3, setIntroMobileImgSrc3] = useState(''); // 소개 상세 이미지(모바일) 3
  const [introMobileImgSrc4, setIntroMobileImgSrc4] = useState(''); // 소개 상세 이미지(모바일) 4
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState('');
  const [contestantId, setContestantId] = useState('');

  var pageSize = 1000;
  var page = 1;

  const queryParameters = new URLSearchParams(window.location.search)
  let pollingResultId = queryParameters.get("pollingResultId") ?? ''
  const fundingId = queryParameters.get("fundingId") ?? ''

  const sessionInfo = Session.getSession();


  //polling class
  let pollingResult = new PollingResult(process.env.REACT_APP_POLLINGRESULTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //funding class
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //ticket class
  let ticket = new Ticket(process.env.REACT_APP_TICKETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {
    if (pollingResultId != '' && pollingResultId != null) {
      var where = 'pollingResultId=' + pollingResultId;
      getData();
      getFundingData(where);
    }

    if (fundingId != '' && fundingId != null) {
      var where = 'fundingId=' + fundingId;
      getFundingData(where);
    }

    getTicketData();
  }, [])

  const getData = async () => {
    var result = await pollingResult.findOne('pollingResultId=' + pollingResultId)
    await setData(result.data.data)
  }

  const getFundingData = async (where) => {
    var result = await funding.findOne(where)

    if (result.data.data.thumbnailLink && result.data.data.thumbnailLink != '') {
      setImgSrc(result.data.data.thumbnailLink);
    }

    if (result.data.data.detailImageLink && result.data.data.detailImageLink != '') {
      setDetailImgSrc(result.data.data.detailImageLink);
    }

    if (result.data.data.detailMobileImageLink && result.data.data.detailMobileImageLink != '') {
      setDetailMobileImgSrc(result.data.data.detailMobileImageLink);
    }

    // 상품소개 이미지 노출 

    if (result.data.data.introImageLink && result.data.data.introImageLink != '') {
      setIntroImgSrc(result.data.data.introImageLink);
    }

    if (result.data.data.introImageLink1 && result.data.data.introImageLink1 != '') {
      setIntroImgSrc1(result.data.data.introImageLink1);
    }

    if (result.data.data.introImageLink2 && result.data.data.introImageLink2 != '') {
      setIntroImgSrc2(result.data.data.introImageLink2);
    }

    if (result.data.data.introImageLink3 && result.data.data.introImageLink3 != '') {
      setIntroImgSrc3(result.data.data.introImageLink3);
    }

    if (result.data.data.introImageLink4 && result.data.data.introImageLink4 != '') {
      setIntroImgSrc4(result.data.data.introImageLink4);
    }

     // 상품소개 이미지 노출 모바일 

    if (result.data.data.introMobImageLink && result.data.data.introMobImageLink != '') {
      setIntroMobileImgSrc(result.data.data.introMobImageLink);
    }

    if (result.data.data.introMobImageLink1 && result.data.data.introMobImageLink1 != '') {
      setIntroMobileImgSrc1(result.data.data.introMobImageLink1);
    }

    if (result.data.data.introMobImageLink2 && result.data.data.introMobImageLink2 != '') {
      setIntroMobileImgSrc2(result.data.data.introMobImageLink2);
    }

    if (result.data.data.introMobImageLink3 && result.data.data.introMobImageLink3 != '') {
      setIntroMobileImgSrc3(result.data.data.introMobImageLink3);
    }

    if (result.data.data.introMobImageLink4 && result.data.data.introMobImageLink4 != '') {
      setIntroMobileImgSrc4(result.data.data.introMobImageLink4);
    }

    await setFundingData(result.data.data)
  }


  const getTicketData = async () => {
    var param = '?pageSize=100&currentPage=0';
    var result = await ticket.findAll(param)
    setTicketData(result.data.data)
    console.log(result.data.data);
  }


  // 파일 업로드 기능
  const handleFileUpload = async (e) => {

    const data = new FormData();
    data.append('media', e.target.files[0])

    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    var result = await funding.uploadFile(fundingId, data, headers);

    if (result.data.codeMsg == 'SUCCESS') {
      // 이미지 업로드 완료
      if (e.target.name == 'thumb_file') {
        // 대표 이미지 
        setImgSrc(result.data.data);
      } else if (e.target.name == 'm_detail_file') {
        // 모바일 상세 페이지 이미지
        setDetailMobileImgSrc(result.data.data);
      } else if (e.target.name == 'intro_file') {
        // 웹 소개 페이지 이미지
        setIntroImgSrc(result.data.data);
      } else if (e.target.name == 'intro_file_1') {
        // 웹 소개 페이지 이미지
        setIntroImgSrc1(result.data.data);
      } else if (e.target.name == 'intro_file_2') {
        // 웹 소개 페이지 이미지
        setIntroImgSrc2(result.data.data);
      } else if (e.target.name == 'intro_file_3') {
        // 웹 소개 페이지 이미지
        setIntroImgSrc3(result.data.data);
      } else if (e.target.name == 'intro_file_4') {
        // 웹 소개 페이지 이미지
        setIntroImgSrc4(result.data.data);
      } else if (e.target.name == 'm_intro_file') {
        // 모바일 소개 페이지 이미지
        setIntroMobileImgSrc(result.data.data);
      } else if (e.target.name == 'm_intro_file_1') {
        // 모바일 소개 페이지 이미지
        setIntroMobileImgSrc1(result.data.data);
      } else if (e.target.name == 'm_intro_file_2') {
        // 모바일 소개 페이지 이미지
        setIntroMobileImgSrc2(result.data.data);
      } else if (e.target.name == 'm_intro_file_3') {
        // 모바일 소개 페이지 이미지
        setIntroMobileImgSrc3(result.data.data);
      } else if (e.target.name == 'm_intro_file_4') {
        // 모바일 소개 페이지 이미지
        setIntroMobileImgSrc4(result.data.data);
      } else {
        // 웹 상세 페이지 이미지
        // setDetailImgSrc(result.data.data);
      }
    } else {
      alert('이미지 업로드에 실패하였습니다. 개발자에게 문의해 주세요.');
    }
  }

  //등록 혹은 수정
  const Submit = async () => {

    // 공모전 PK
    var contestantId = document.getElementById("contestantId").value;
    // pollingResultId PK
    pollingResultId = pollingResultId ?? document.getElementById("pollingResultId").value;
    // 카테고리 코드
    var categoryCode = document.getElementById("categoryCode").value;
    // 작가명
    var writerName = document.getElementById("writerName").value ?? '';
    // 시놉시스 요약 
    var description = document.getElementById("description").value ?? '';
    // 시놉시스 장르
    var genres = document.getElementById("genres").value ?? '';
    // 시놉시스 제목
    var synopsisTitle = document.getElementById("synopsisTitle").value ?? '';
    // 펀딩 시작일
    var startAt = document.getElementById("startAt").value ?? '';
    // 펀딩 종료일 
    var endAt = document.getElementById("endAt").value ?? '';
    // 펀딩 목표 금액
    var targetAmount = document.getElementById("targetAmount").value ?? '';
    // 대표 이미지 링크
    var thumbnailLink = document.getElementById("thumbnailLink").value ?? '';
    // // 상품설명 이미지 링크
    var detailImageLink = '';
    // // 상품설명 이미지 링크(모바일)
    var detailMobileImageLink = '';
    // 시놉시스 소개
    var introduce = document.getElementById("introduce").value ?? '';
    // 캐릭터 소개
    var characterIntro = document.getElementById("characterIntro").value ?? '';
    // 펀딩 은행
    var bankName = document.getElementById("bankName").value ?? '';
    // 펀딩 계좌번호
    var bankAccount = document.getElementById("bankAccount").value ?? '';
    // 티켓 아이디 
    var ticketId = document.getElementById("ticketId").value ?? '';


    // 상품설명 이미지 링크
    var introImageLink = document.getElementById("introImageLink").value ?? '';
    // 상품설명 이미지 링크(모바일)
    var introMobImageLink = document.getElementById("introMobImageLink").value ?? '';
    // 상품설명 이미지 링크
    var introImageLink1 = document.getElementById("introImageLink1").value ?? '';
    // 상품설명 이미지 링크(모바일)
    var introMobImageLink1 = document.getElementById("introMobImageLink1").value ?? '';
    // 상품설명 이미지 링크
    var introImageLink2 = document.getElementById("introImageLink2").value ?? '';
    // 상품설명 이미지 링크(모바일)
    var introMobImageLink2 = document.getElementById("introMobImageLink2").value ?? '';
    // 상품설명 이미지 링크
    var introImageLink3 = document.getElementById("introImageLink3").value ?? '';
    // 상품설명 이미지 링크(모바일)
    var introMobImageLink3 = document.getElementById("introMobImageLink3").value ?? '';
    // 상품설명 이미지 링크
    var introImageLink4 = document.getElementById("introImageLink4").value ?? '';
    // 상품설명 이미지 링크(모바일)
    var introMobImageLink4 = document.getElementById("introMobImageLink4").value ?? '';


    var data = {
      "fundingId": "",
      "pollingResultId": fundingData.pollingResultId ?? (pollingResultId ?? ''),
      "contestantId": contestantId,
      "categoryCode": categoryCode,
      "writerName": writerName,
      "description": description,
      "genres": genres,
      "synopsisTitle": synopsisTitle,
      "startAt": startAt,
      "endAt": endAt,
      "targetAmount": targetAmount,
      "bankName": bankName,
      "bankAccount": bankAccount,
      "thumbnailLink": thumbnailLink,
      "introduce": introduce,
      "characterIntro": characterIntro,
      "ticketId": ticketId,
      "detailImageLink": detailImageLink,
      "detailMobileImageLink": detailMobileImageLink,

      "introImageLink": introImageLink,
      "introMobImageLink": introMobImageLink,
      "introImageLink1": introImageLink1,
      "introMobImageLink1": introMobImageLink1,
      "introImageLink2": introImageLink2,
      "introMobImageLink2": introMobImageLink2,
      "introImageLink3": introImageLink3,
      "introMobImageLink3": introMobImageLink3,
      "introImageLink4": introImageLink4,
      "introMobImageLink4": introMobImageLink4,
    };


    if (fundingId && fundingId != '' && fundingId != null) {
      data.fundingId = fundingId
    }

    const result = fundingId != '' && fundingId != null ? await funding.update(data) : await funding.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/funding';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>

          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {fundingId != null ? <h3 className="Category">펀딩 수정</h3> : <h3 className="Category">펀딩 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Input
                    type="hidden"
                    name="contestantId"
                    defaultValue={data.contestantId ?? (fundingData.contestantId ?? '')}
                    placeholder="categoryCode"
                    className="fs-5"
                    id="contestantId"
                  />
                  <Input
                    type="hidden"
                    name="pollingResultId"
                    defaultValue={fundingData.pollingResultId ?? (pollingResultId ?? '')}
                    placeholder="categoryCode"
                    className="fs-5"
                    id="pollingResultId"
                  />
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>카테고리</label>
                      <Input
                        name="categoryCode"
                        defaultValue={data.categoryCode ?? (fundingData.categoryCode ?? 'P01_01_02')}
                        placeholder="categoryCode"
                        type="text"
                        className="fs-5"
                        id="categoryCode"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>서브타이틀</label>
                      <textarea
                        name="genres"
                        defaultValue={data.genres ?? (fundingData.genres ?? '')}
                        placeholder="서브타이틀을 입력해 주세요.(구분자 ,)"
                        className="fs-5 w-100"
                        id="genres">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>작가명</label>
                      <Input
                        name="writerName"
                        defaultValue={data.writerName ?? (fundingData.writerName ?? '')}
                        placeholder="작가명을 입력해 주세요"
                        type="text"
                        className="fs-5"
                        id="writerName"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 작품명</label>
                      <Input
                        name="synopsisTitle"
                        defaultValue={data.synopsisTitle ?? (fundingData.synopsisTitle ?? '')}
                        placeholder="작품명을 입력해 주세요"
                        type="text"
                        className="fs-5"
                        id="synopsisTitle"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 설명</label>
                      <textarea
                        name="description"
                        defaultValue={data.description ?? (fundingData.description ?? '')}
                        placeholder="시놉시스 설명글을 작성해 주세요."
                        className="fs-5 w-100"
                        id="description">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 시작일</label>
                      <Input
                        name="startAt"
                        defaultValue={fundingData.startAt ?? ''}
                        placeholder="startAt"
                        type="date"
                        className="fs-5"
                        id="startAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 종료일</label>
                      <Input
                        name="endAt"
                        defaultValue={fundingData.endAt ?? ''}
                        placeholder="endAt"
                        type="date"
                        className="fs-5"
                        id="endAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 목표 금액</label>
                      <input
                        name="targetAmount"
                        type="number"
                        defaultValue={fundingData.targetAmount ?? ''}
                        placeholder="목표금액을 작성해 주세요."
                        className="fs-5 w-100"
                        id="targetAmount" />
                    </FormGroup>
                  </Col>
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>대표 이미지 업로드</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>대표 이미지 업로드(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="thumb_file" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="thumbnailLink"
                          id="thumbnailLink"
                          type="hidden"
                          defaultValue={imgSrc}
                        />
                        <img src={imgSrc} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드(WEB)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="intro_file" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introImageLink"
                          id="introImageLink"
                          type="hidden"
                          defaultValue={introImgSrc}
                        />
                        <img src={introImgSrc} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드1(WEB)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드1(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="intro_file_1" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introImageLink1"
                          id="introImageLink1"
                          type="hidden"
                          defaultValue={introImgSrc1}
                        />
                        <img src={introImgSrc1} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드2(WEB)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드2(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="intro_file_2" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introImageLink2"
                          id="introImageLink2"
                          type="hidden"
                          defaultValue={introImgSrc2}
                        />
                        <img src={introImgSrc2} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드3(WEB)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드3(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="intro_file_3" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introImageLink3"
                          id="introImageLink3"
                          type="hidden"
                          defaultValue={introImgSrc3}
                        />
                        <img src={introImgSrc3} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드4(WEB)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드4(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="intro_file_4" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introImageLink4"
                          id="introImageLink4"
                          type="hidden"
                          defaultValue={introImgSrc4}
                        />
                        <img src={introImgSrc4} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드(Mob)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="m_intro_file" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introMobImageLink"
                          id="introMobImageLink"
                          type="hidden"
                          defaultValue={introMobileImgSrc}
                        />
                        <img src={introMobileImgSrc} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드1(Mob)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드1(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="m_intro_file_1" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introMobImageLink1"
                          id="introMobImageLink1"
                          type="hidden"
                          defaultValue={introMobileImgSrc1}
                        />
                        <img src={introMobileImgSrc1} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드2(Mob)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드2(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="m_intro_file_2" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introMobImageLink2"
                          id="introMobImageLink2"
                          type="hidden"
                          defaultValue={introMobileImgSrc2}
                        />
                        <img src={introMobileImgSrc2} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드3(Mob)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드3(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="m_intro_file_3" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introMobImageLink3"
                          id="introMobImageLink3"
                          type="hidden"
                          defaultValue={introMobileImgSrc3}
                        />
                        <img src={introMobileImgSrc3} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  {fundingId != null &&
                    <Col className="pr-md-1 titleAdmin" md="6">
                      <label>시놉시스 소개 및 작품요약 이미지 업로드4(Mob)</label>
                      <FormGroup>
                        <label style={{ cursor: "pointer" }}>시놉시스 소개 및 작품요약 이미지 업로드4(여기를 눌러 업로드 해주세요)</label>
                        <input type="file" name="m_intro_file_4" placeholder="이미지를 업로드 해주세요" required onChange={handleFileUpload} />
                        <input
                          name="introMobImageLink4"
                          id="introMobImageLink4"
                          type="hidden"
                          defaultValue={introMobileImgSrc4}
                        />
                        <img src={introMobileImgSrc4} id="preview-img" style={{ width: "100%" }}></img>
                      </FormGroup>
                    </Col>
                  }
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 요약</label>
                      <textarea
                        name="introduce"
                        defaultValue={fundingData.introduce ?? ''}
                        placeholder="시놉시스 전문/요약을 작성해 주세요."
                        className="fs-5 w-100"
                        id="introduce">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>작품 소개</label>
                      <textarea
                        name="characterIntro"
                        defaultValue={fundingData.characterIntro ?? ''}
                        placeholder="작품 소개를 작성해 주세요."
                        className="fs-5 w-100"
                        id="characterIntro">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                      <label>
                        티켓 선택
                        <Input type="select" name="ticketId" id="ticketId" className="mt-3">
                          {ticketData && ticketData.map((prop, key) => {
                            var selected = '';
                            if (prop.ticketId == fundingData.ticketId) {
                              selected = 'selected'
                            }
                            return <option value={prop ? prop.ticketId : ''} selected={selected}>{prop.ticketName ?? ''}/{prop.type && prop.type == "CONTENT" ? '콘텐츠 제작 티켓' : '밈티켓'}</option>
                          })}
                        </Input>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 은행명</label>
                      <textarea
                        name="bankName"
                        defaultValue={fundingData.bankName ?? ''}
                        placeholder="펀딩 은행명을 작성해 주세요."
                        className="fs-5 w-100"
                        id="bankName">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 은행 계좌번호</label>
                      <textarea
                        name="bankAccount"
                        defaultValue={fundingData.bankAccount ?? ''}
                        placeholder="펀딩 은행 계좌번호를 작성해 주세요."
                        className="fs-5 w-100"
                        id="bankAccount">
                      </textarea>
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FundingCreate;
