import axios from "axios";
import Event from "module/event/Event";

// Settlement class  
class Settlement extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }


    async findOne(data){
        //특정 리스트 가져오기
        const url  = `${this.baseUrl}/?`+data; // api 전달 url
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async update(data) {
        const url = `${this.baseUrl}/`;
        const method = "PATCH";

        return await this.sendAxios(url, method, data); //make option
    }
}

export default Settlement;