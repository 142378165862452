/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from "react-select";

// control session
import Session from "module/session/Session";
// module polling 
import Pay from "module/pay/Pay";
// module funding 
import Funding from "module/funding/Funding";
// module ticket 
import Ticket from "module/ticket/Ticket";
// module project
import user from "module/user/User";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function PayCreate() {

  const [data, setData] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [fundingId, setFundingId] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [userId, setUserId] = useState('');

  var pageSize = 5000;
  var page = 1;

  const queryParameters = new URLSearchParams(window.location.search)
  const payId = queryParameters.get("payId") ?? ''

  const sessionInfo = Session.getSession();


  //polling class
  let pay = new Pay(process.env.REACT_APP_WITHDRAWAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //funding class
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //ticket class
  let ticket = new Ticket(process.env.REACT_APP_TICKETAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  //user class
  let user = new Ticket(process.env.REACT_APP_USERAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {
    if (payId != '' && payId != null) {
      var where = 'payId=' + payId;
      getData();
      getFundingData(where);
    }

    getFundingData();
    getTicketData();
    getUserData();
  }, [])

  const getData = async () => {
    var result = await pay.findOne('payId=' + payId)

    await setUserId(result.data.data.memberId);
    await setTicketId(result.data.data.ticketId);
    await setFundingId(result.data.data.fundingId);
    await setData(result.data.data)
  }

  const getFundingData = async (where) => {
    var param = '?pageSize=5000&currentPage=0';
    var result = await funding.findAll(param)

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].fundingId) {
          result_arr.push({
            "value": result.data.data[i].fundingId,
            "label": result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setFundingData(result_arr)
  }

  const getUserData = async (where) => {
    var param = '?pageSize=5000&currentPage=0';
    var result = await user.findAll(param);

    var result_arr = [];

    if (result && result.data.data) {
      for (var i = 0; i < result.data.data.content.length; i++) {
        if (result.data.data.content[i].id) {
          result_arr.push({
            "value": result.data.data.content[i].id,
            "label": result.data.data.content[i].nickName+'/'+result.data.data.content[i].email
          });
        }
      }
    }

    await setUserData(result_arr)
  }

  const getTicketData = async () => {
    var param = '?pageSize=1000&currentPage=0';
    var result = await ticket.findAll(param)

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].ticketId) {
          result_arr.push({
            "value": result.data.data[i].ticketId,
            "label": result.data.data[i].ticketName
          });
        }
      }
    }

    setTicketData(result_arr)
  }

  //등록 혹은 수정
  const Submit = async () => {

    // 티켓 금액
    var ticketAmount = document.getElementById("ticketAmount").value ?? '';
    // 티켓 아이디 
    var ticketCnt = document.getElementById("ticketCnt").value ?? '';
    // 추천인 아이디 
    // var referralNickname = document.getElementById("referralNickname").value ?? '';

    var data = {
      "totalAmount": Number(ticketAmount) * Number(ticketCnt),
      "ticketAmount": ticketAmount,
      "ticketCount": Number(ticketCnt),
      "memberId": userId,
      "ticketId": ticketId,
      "fundingId": fundingId,
      "payType": "manual_transfer", // 결제유형 추가 -- 토스 추가되어서 
      "orderName": '[MYM-B]콘텐츠 제작 티켓', // 주문상품명 추가 -- 토스 추가되어서 
      "status": 'O1',
      // "referralTo": referralNickname ?? ''
    }


    if (payId && payId != '' && payId != null) {
      data.payId = payId
    }

    const result = payId != '' && payId != null ? await pay.update(data) : await pay.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/payList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>

          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {payId != null ? <h3 className="Category">수동 출금내역 수정</h3> : <h3 className="Category">수동 출금내역 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓 금액</label>
                      <Input
                        name="ticketAmount"
                        defaultValue={data.ticketAmount ?? ''}
                        placeholder="티켓금액"
                        type="number"
                        className="fs-5"
                        id="ticketAmount"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                  <FormGroup>
                      <label>티켓갯수</label>
                      <Input
                        name="ticketCnt"
                        defaultValue={data.ticketCnt ?? ''}
                        placeholder="티켓갯수"
                        type="number"
                        className="fs-5"
                        id="ticketCnt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>펀딩 선택</label>
                      <Select options={fundingData} //위에서 만든 배열을 select로 넣기
                        onChange={(e) => setFundingId(e.value)}
                        name="fundingId"
                        className=""
                        placeholder="펀딩 선택"
                        value={fundingData.filter(function (option) {
                          if (fundingId) {
                            return option.value === fundingId;
                          }
                        })} />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>티켓 선택</label>
                      <Select options={ticketData} //위에서 만든 배열을 select로 넣기
                        onChange={(e) => setTicketId(e.value)}
                        name="ticketId"
                        className=""
                        placeholder="티켓 선택"
                        value={ticketData.filter(function (option) {
                          if (ticketId) {
                            return option.value === ticketId;
                          }
                        })} />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>회원 선택</label>
                      <Select options={userData} //위에서 만든 배열을 select로 넣기
                        onChange={(e) => setUserId(e.value)}
                        name="userId"
                        className=""
                        placeholder="회원 선택"
                        value={userData.filter(function (option) {
                          if (userId) {
                            return option.value === userId;
                          }
                        })} />
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  등록
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PayCreate;
