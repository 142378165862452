/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NICE_BANK_CODE } from "../../lib/global_variable"
// control session
import Session from "module/session/Session";
// module pay 
import Platform from "module/platform/Platform";
// Funding class 생성
import Funding from "module/funding/Funding";
// Ticket class 생성
import Ticket from "module/ticket/Ticket";
// User class 생성
import User from "module/user/User";

// reactstrap components
import {
  Button,
  Card,
  Label,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function PlatformView() {

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState('');
  const [contestantId, setContestantId] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const platformId = queryParameters.get("platformId")

  const sessionInfo = Session.getSession();

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const formattedDateTime = now.toISOString().replace('Z', '');



  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  const state = {
    "O1": "신청",
    "CD": "취소",
    "HD": "승인보류",
    "OD": "승인완료",
  };

  useEffect(() => {
    if (platformId != '' && platformId != null) {
      getData();
    }
  }, [])


  const getData = async () => {
    var result = await platform.findOne('platformId=' + platformId)

    if (result.data.data) {
      setData(result.data.data);
    }

  }

  //상태값 업데이트 하기 
  const updateState = async () => {
    var created_at = document.getElementById('createdAt_' + platformId).value;

    if (created_at.length == 22) {
      created_at = created_at + '1';
    }

    var result = await platform.update('?platformId=' + platformId, {
      platformId: platformId,
      status: document.getElementById('changeState').value
    })

    console.log(result);

    if (result.data.code == 200) {
      alert('상태값 변경을 완료 하였습니다.');
      window.location.reload();
    } else {
      alert('상태값 변경에 실패 하였습니다.');
    }
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">수익플랫폼 정보</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>플랫폼명</label>
                    <p>{data.name ? data.name : '플랫폼명'}</p>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PlatformView;
