/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// mobule polling 
import Polling from "module/polling/Polling";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function ProjectCreate() {

  const [data, setData] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search)
  const pollingId = queryParameters.get("pollingId")

  const sessionInfo = Session.getSession();


  //polling class
  let polling = new Polling(process.env.REACT_APP_POLLINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {
    if (pollingId != '' && pollingId != null) {
      getData();
    }
  }, [])

  const getData = async () => {
    var result = await polling.findOne('pollingId='+pollingId)
    await setData(result.data.data)
  }

  //등록 혹은 수정
  const Submit = async () => {

    var categoryCode = document.getElementById("categoryCode").value;
    var requiredPollCount = document.getElementById("requiredPollCount").value;
    var startAt = document.getElementById("startAt").value;
    var endAt = document.getElementById("endAt").value;

    var data = {
      "requiredPollCount": requiredPollCount,
      "startAt": startAt,
      "endAt": endAt,
      "categoryCode": categoryCode
    };


    if (pollingId != '' && pollingId != null) {
      data = {
        "requiredPollCount": requiredPollCount,
        "startAt": startAt,
        "endAt": endAt,
        "categoryCode": categoryCode,
        "pollingId": pollingId,
      };
    }

    const result = pollingId != '' && pollingId != null ? await polling.update(data) : await polling.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/project';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {pollingId != '' && pollingId != null ? <h3 className="Category">투표 제한 수 수정</h3> : <h3 className="Category">투표 제한 수 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>카테고리</label>
                      <Input
                        name="categoryCode"
                        defaultValue={data.categoryCode}
                        placeholder="categoryCode"
                        type="text"
                        className="fs-5"
                        id="categoryCode"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>제한 수</label>
                      <Input
                        name="requiredPollCount"
                        defaultValue={data.requiredPollCount}
                        placeholder="requiredPollCount"
                        type="number"
                        className="fs-5"
                        id="requiredPollCount"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시작일</label>
                      <Input
                        name="startAt"
                        defaultValue={data.startAt}
                        placeholder="startAt"
                        type="date"
                        className="fs-5"
                        id="startAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>종료일</label>
                      <Input
                        name="endAt"
                        defaultValue={data.endAt}
                        placeholder="endAt"
                        type="date"
                        className="fs-5"
                        id="endAt"
                      />
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ProjectCreate;
