/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {escapeHtml,unescapeHtml} from "../../../lib/global_function";

// control session
import Session from "module/session/Session";
// mobule notice 
import DaoProjectNotice from "module/dao/DaoProjectNotice";
// funding class 생성
import Funding from "module/funding/Funding";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function NotiCreate() {

  const [data, setData] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search)
  const projectNoticeId = queryParameters.get("projectNoticeId")

  var pageSize = 1000;
  const [page, setPage] = useState(1);
  const [imgSrc, setImgSrc] = useState('');
  const [content, setContent] = useState('');

  const sessionInfo = Session.getSession();

  const [fundingName, setFundingName] = useState('');
  const [fundingList, setFundingList] = useState([]);

  //Event class
  let notice = new DaoProjectNotice(process.env.REACT_APP_DAOPROJECTNOTICEAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  // 펀딩리스트를 통해서 펀딩 정보 저장 처리 추가 
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });


  useEffect(() => {
    if (projectNoticeId != '' && projectNoticeId != null) {
      getData();
    }

    loadFundingList();
  }, [])

  const getData = async () => {
    var result = await notice.findOne('projectNoticeId=' + projectNoticeId)
    await setData(result.data.data)
  }

  const loadFundingList = async () => {
    // 펀딩 리스트도 가져오기 => 저장시 필요 
    var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
    var result = await funding.findAll(param);

    setFundingList(result.data.data);
  }

  const changeFundingName = async () => {
    var target = document.getElementById("fundingId");
    setFundingName(target.options[target.selectedIndex].text);
  }

  const customUploadAdapter = (loader) => { // (2)
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(async (file) => {
            // data.append("name", file.name);
            data.append("media", file);

            console.log(file);

            var headers = {
              'Content-Type': 'application/x-www-form-urlencoded'
            }

            var result = await notice.uploadFile(projectNoticeId, data, headers);

            console.log(result);

            if (result.data.codeMsg == 'SUCCESS') {
              // 이미지 업로드 완료
              setImgSrc(result.data.data);

              resolve({
                default: `${result.data.data}`
              });
            } else {
              alert('이미지 업로드에 실패하였습니다. 개발자에게 문의해 주세요.');
            }

          })
        })
      }
    }
  }

  function uploadPlugin(editor) { // (3)
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    }
  }

  //등록 혹은 수정
  const Submit = async () => {

    var title = document.getElementById("title").value;
    var contents = content;
    var fundingId = document.getElementById("fundingId").value;
    var fundingGenre = document.getElementById("fundingGenre").value;

    console.log(content);

    var data = {
      "title": title,
      "content": contents,
      "fundingId": fundingId,
      "fundingName": fundingName,
      "fundingGenre": fundingGenre,
    };

    if (projectNoticeId != '' && projectNoticeId != null) {
      data = {
        "title": title,
        "content": contents,
        "fundingId": fundingId,
        "fundingName": fundingName,
        "fundingGenre": fundingGenre,
        "noticeId": projectNoticeId,
      };
    }

    const result = projectNoticeId != '' && projectNoticeId != null ? await notice.update(data) : await notice.create(data);

    console.log(result);

    if (result.data.code == 200) {
      //  alert('등록완료');
      window.location.href = '/admin/dao/ProjectNoticeList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {projectNoticeId != '' && projectNoticeId != null ? <h3 className="Category">DAO 공지사항 수정</h3> : <h3 className="Category">DAO 공지사항 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>프로젝트 정보</label>
                      <Input type="select" name="fundingId" id="fundingId" className="mt-3" onChange={changeFundingName}>
                        <option value=''>프로젝트를 선택해 주세요.</option>
                        {fundingList && fundingList.map((prop, key) => {
                          var selected = '';
                          if (prop.fundingId == data.fundingId) {
                            selected = 'selected'
                          }
                          return <option value={prop ? prop.fundingId : ''} selected={selected}>{prop.synopsisTitle ?? ''}</option>
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>프로젝트 장르</label>
                      <textarea
                        name="fundingGenre"
                        defaultValue={data.fundingGenre ?? ''}
                        placeholder="장르를 입력해주세요.(여러개일 경우, 구분자 , 를 사용하여 입력해주세요.)"
                        className="fs-5 w-100"
                        id="fundingGenre">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>제목</label>
                      <Input
                        name="title"
                        defaultValue={data.title}
                        placeholder="Title"
                        type="text"
                        className="fs-5"
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Input type="hidden" name="content" id="content" value={data.content}>
                    </Input>
                    <Col md="8">
                      <CKEditor
                        name="content"
                        editor={ClassicEditor}
                        data={unescapeHtml(data.content)}
                        config={{ // (4)
                          extraPlugins: [uploadPlugin]
                        }}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);

                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          document.getElementById('content').value = data;
                          setContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NotiCreate;
