export const escapeHtml =(text) => {

    if(typeof text != 'undefined' && text != ''){
        text = text.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', "'");
    }
    
    return text
  }

export const unescapeHtml = (text) => {
    if(typeof text != 'undefined' && text != ''){
        text = text.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>').replaceAll("'", '"');
    }

    return text;
}