/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list
 
*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import FAQ from "module/faq/FAQ";

import Session from "module/session/Session"; 

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText, 
  FormGroup,
  Form,
  Input,
  Row,
  Label,
  Col
} from "reactstrap";

function FaqCreate() {

  const sessionInfo = Session.getSession();

  //faq class
  let faq = new FAQ(
    process.env.REACT_APP_FAQAPI,{
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  const [value, setValue] = useState(new Date().toISOString())
  const [type, setType] = useState('등록');
  const [data,setData] = useState([]);
  const [content, setContent] = useState('');
  const [selectValue,setSelectValue] = useState('');

  // check get param about faqId
  const queryParameters = new URLSearchParams(window.location.search)
  const faqId = queryParameters.get("faqId")

  useEffect(() => {

    if (faqId) {
      setType('수정');
      getData();
    }
    
  }, []);

  const getData = async() => {
    var result = await faq.findOne('faqId='+faqId)
    setSelectValue(result.data.data.faqCategory);
    await setData(result.data.data)
  }

  const handleCategory = (e) =>{
    setSelectValue(e.target.value);
  }

  //등록 혹은 수정
  const Submit = async () => {

    var question = document.getElementById("question").value;
    var answer = content;
    var faqCategory = document.getElementById("faqCategory").value;
    

    var data = {
      "question": question,
      "answer": answer,
      "faqCategory": faqCategory
    };

    if(faqId != '' && faqId != null ) {
      data = {
        "question": question,
        "answer": answer,
        "faqId": faqId,
        "faqCategory": faqCategory
      };
    
    }

    const result =  faqId != '' && faqId != null ? await faq.update(data) : await faq.create(data);

    if (result.data.code == 200) {
      // faqId != '' && faqId != null ?  alert('수정완료')  : alert('등록완료');
      window.location.href = '/admin/faq'
    } else {
      alert('등록실패')
    }

  }

  return (
    <>
      <div className="content">
        <Row>
        <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {faqId != '' && faqId != null ? <h3 className="Category">FAQ 수정</h3> : <h3 className="Category">FAQ 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                <Col className="pr-md-1 typeAdmin" md="6">
                  <FormGroup>
                    <Label for="exampleSelect">유형</Label>
                    <Input type="select" name="faqCategory" id="faqCategory" value={selectValue} onChange={handleCategory}>
                      <option value="CONTEST">공모전</option>
                      <option value="PAYMENT">결제/환불/취소</option>
                      <option value="SERVICE">서비스</option>
                      <option value="PERSONAL_INFO" selected>일반</option>
                      <option value="MEMBER">회원</option>
                    </Input>
                  </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>질문</label>
                      <Input
                        name="question"
                        id="question"
                        defaultValue={data.question}
                        placeholder="question"
                        type="text"
                        className="fs-5"
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Input type="hidden" name="answer" id="answer" value="">
                    </Input>
                    <Col md="8">
                      <CKEditor
                        name="answer"
                        editor={ClassicEditor}
                        data={data.answer}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);

                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          document.getElementById('content').value = data;
                          setContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick ={() => { Submit() }} >
                  등록하기
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FaqCreate;
