/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// project class 생성
import Project from "module/project/Project";


// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function ProjectList() {

    const [list, setList] = useState([]);
    const sessionInfo = Session.getSession();
    var pageSize = 100;
    
    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);

    const state = {
        "PENDING":   "승인중", 
        "RECEIVED":  "승인완료",
        "REVIEWING": "심사중",
        "ACCEPTED":  "심사완료",
        "POLLING":   "투표중",
        "SELECTED":  "투표선정",
        "DROPPED":   "투표탈락"
    };

    const category = {
        "P01_01_01":   "공모전"
    };

    let project = new Project(process.env.REACT_APP_PROJECTAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    const loadList = async (page) => {
        var param = '?pageSize='+pageSize+'&currentPage='+(page - 1);
        var result = await project.findAll(param);

        setList(result.data.data);
    }

    const setPagination = () => {

        var startPage = page - 1;
        var endPage = page + 1;

        if (page == 1) {
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<spanagination aria-label="Page navigation example"><spanaginationItem><spanaginationLink previous onClick={() => loadList(' + startPage + ') }/></PaginationItem>';

        for (var i = startPage; i <= endPage; i++) {
            pagination = '<spanaginationItem><spanaginationLink onClick={() => loadList(' + i + ') }/>' + i + '</PaginationItem>';
        }

        pagination += '<spanaginationItem><spanaginationLink next  onClick={() => loadList(' + endPage + ') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }

    //카테고리 기준 리스트 
    const getListByCategory = async (e) => {

        var result = await project.findCateAll(e.target.value, {
            pageSize: pageSize,
            currentPage: page - 1
        });

        setList(result.data.data);
    }

    //상태값 기준 리스트 
    const getListByState = async (e) => {

        if(e.target.value != ''){
            loadList(page);
        }else{
            var result = await project.findStateAll(e.target.value, {
                pageSize: pageSize,
                currentPage: page - 1
            });
        }

        setList(result.data.data);
    }

    //상태값 업데이트 하기 
    const updateState = async () => {

        var updateList = document.querySelectorAll(".contestantId")

    console.log(updateList);

        if (updateList.length < 1) {
            alert('상태값을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if(element.checked && document.getElementById('changeState').value != ''){
                    var result = await project.update({
                        userId: element.dataset.id,
                        contestantId: element.value,
                        categoryCode: document.getElementById('projectCategory').value,
                        contestantState: document.getElementById('changeState').value
                    })
    
                    console.log(result);
                }
            });

            // alert('상태값을 변경하였습니다')
            window.location.reload();

        }

    }

    // 체크 리스트 삭제 처리 
    const deleteList = async () => { 

        var deleteList = document.querySelectorAll(".contestantId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if(element.checked){
                    var result = await project.remove(element.value)
                    console.log(result);
                }
            });
            // alert('상태값을 변경하였습니다')
            // window.location.reload();

        }

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <div className="d-inline-flex">
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            카테고리
                                            <Input type="select" name="projectCategory" id="projectCategory" className="mt-3" onChange={getListByCategory}>
                                                <option value="P01_01_01">공모전 프로젝트</option>
                                                {/* <option value="P01_01_02">개인미디어 프로젝트</option> */}
                                            </Input>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            진행상태 
                                            <Input type="select" name="state" id="state" className="mt-3" onChange={getListByState}>
                                                <option value="">진행상태</option>
                                                <option value="PENDING">승인중</option>
                                                <option value="RECEIVED">승인완료</option>
                                                <option value="REVIEWING">심사중</option>
                                                <option value="ACCEPTED">심사완료</option>
                                                <option value="POLLING">투표중</option>
                                                <option value="SELECTED">투표선정</option>
                                                <option value="DROPPED">투표탈락</option>
                                            </Input>
                                        </Label>
                                    </FormGroup>
                                </div>
                                <Link to='/admin/projectCreate'>
                                    <Button style={{ float: "right" }}>투표수 등록하기</Button>
                                </Link>
                                {/* <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button> */}
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <tbody>
                                            {list.map((project, ind) => {
                                                return <tr>
                                                            <td className="w-5">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input defaultValue={project.contestantId} data-id={project.userId} type="checkbox" className="contestantId" />
                                                                        <span className="form-check-sign">
                                                                            <span className="check" />
                                                                        </span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                    {category[project.categoryCode]}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                    {project.email}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                    {project.nickName}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block' }}>
                                                                    {project.firstName} {project.lastName}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="title fs-4" style={{ display: 'inline-block' }}>
                                                                    <a href={project.uploadUrl} download={project.fileName} target="_blank">
                                                                        {project.fileName ?? '파일 보기'}
                                                                    </a>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                                    {project.createdAt}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                                    {project.updatedAt}
                                                                </span>
                                                            </td>
                                                            <td className="td-actions text-right">
                                                                <Badge className={project.contestantState}>
                                                                    {state[project.contestantState]}
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="pagination">

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <span>
                    선택된 리스트 진행상태
                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                        <Label check>
                            <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                                <option value="">진행상태</option>
                                <option value="PENDING">승인중</option>
                                <option value="RECEIVED">승인완료</option>
                                <option value="REVIEWING">심사중</option>
                                <option value="ACCEPTED">심사완료</option>
                                <option value="POLLING">투표중</option>
                                <option value="SELECTED">투표선정</option>
                                <option value="DROPPED">투표탈락</option>
                            </Input>
                        </Label>
                    </FormGroup>
                    으로 변경
                </span>
            </div>
        </>
    );
}

export default ProjectList;
