import axios from "axios";
import Event from "module/event/Event";

// Platform class  
class Platform extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

}

export default Platform;