/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// funding class 생성
import Funding from "module/funding/Funding";


// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function FundingList() {

    const [list, setList] = useState([]);
    const sessionInfo = Session.getSession();
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);

    const category = {
        "P01_01_01": "공모전",
        "P01_01_02": "성인웹툰"
    };

    //funding class
    let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
        var result = await funding.findAll(param);

        setList(result.data.data);
    }

    const setPagination = () => {

        var startPage = page - 1;
        var endPage = page + 1;

        if (page == 1) {
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<spanagination aria-label="Page navigation example"><spanaginationItem><spanaginationLink previous onClick={() => loadList(' + startPage + ') }/></PaginationItem>';

        for (var i = startPage; i <= endPage; i++) {
            pagination = '<spanaginationItem><spanaginationLink onClick={() => loadList(' + i + ') }/>' + i + '</PaginationItem>';
        }

        pagination += '<spanaginationItem><spanaginationLink next  onClick={() => loadList(' + endPage + ') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }


    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".fundingId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await funding.remove(element.value)
                    console.log(result);
                }
            });

            // alert('상태값을 변경하였습니다')
            // window.location.reload();

        }

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <tbody>
                                            {list.map((funding, ind) => {
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input defaultValue={funding.fundingId} data-id={funding.fundingId} type="checkbox" className="fundingId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {category[funding.categoryCode]}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {funding.writerName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {funding.synopsisTitle}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {funding.targetAmount}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {funding.startAt}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {funding.endAt}
                                                        </span>
                                                    </td>
                                                    <td className="td-actions text-right">
                                                    <Link to={`/admin/fundingCreate?fundingId=`+funding.fundingId}>
                                                        <Button
                                                            color="link"
                                                            id="tooltip457194718"
                                                            title=""
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-pencil" />
                                                        </Button>
                                                    </Link>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip457194718"
                                                        placement="right"
                                                    >
                                                       수정하기
                                                    </UncontrolledTooltip>
                                                </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="pagination">

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default FundingList;
