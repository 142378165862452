import axios from "axios";
import Event from "module/event/Event";

// User class  
class User extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

}

export default User;