/*
*
* manage login session(jwt)
*
*/
var Session = (function() {
  
    var getSession = function() {
        return {
            userId :  typeof window !== 'undefined' ? sessionStorage.getItem('userId') : null,
            managerType: typeof window !== 'undefined' ? sessionStorage.getItem('managerType') : null,
            managerId: typeof window !== 'undefined' ? sessionStorage.getItem('managerId') : null,
            token : typeof window !== 'undefined' ? sessionStorage.getItem('token') : null,
        }
    };
  
    var setSession = function(emailValue, managerTypeValue, managerIdValue, tokenValue) {
        sessionStorage.setItem('userId', emailValue);
        sessionStorage.setItem('managerType', managerTypeValue);
        sessionStorage.setItem('managerId', managerIdValue);
        sessionStorage.setItem('token', tokenValue);
    };
  
    return {
        getSession: getSession,
        setSession: setSession
    }
  
  })();
  
  export default Session;
