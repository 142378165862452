/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// mobule notice 
import Notice from "module/notice/Notice";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function NotiCreate() {

  const [data,setData] = useState([]);
  const [content, setContent] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const noticeId = queryParameters.get("noticeId")

  const sessionInfo = Session.getSession();


  //Event class
  let notice = new Notice(process.env.REACT_APP_NOTICEAPI,{
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(()=>{
    if(noticeId != '' && noticeId != null){
        getData();
    }
  },[])

  const getData = async() => {
    var result = await notice.findOne('noticeId='+noticeId)
    await setData(result.data.data)
  }

  //등록 혹은 수정
  const Submit = async () => {

    var title = document.getElementById("title").value;
    var contents = content;
  
    var data = {
      "title": title,
      "content": contents
    };

    if(noticeId != '' && noticeId != null) {
      data = {
        "title": title,
        "content": contents,
        "noticeId": noticeId,
      };
    }

    const result =  noticeId != '' && noticeId != null ? await notice.update(data) : await notice.create(data);

    console.log(result);

    if(result.data.code == 200) {
      //  alert('등록완료');
       window.location.href = '/admin/notice';
    } else {
       alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                 {noticeId != '' && noticeId != null ? <h3 className="Category">공지사항 수정</h3> : <h3 className="Category">공지사항 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>제목</label>
                      <Input
                        name="title"
                        defaultValue={data.title}
                        placeholder="Title"
                        type="text"
                        className="fs-5"
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Input type="hidden" name="content" id="content" value={data.content}>
                    </Input>
                    <Col md="8">  
                      <CKEditor
                        name="content"
                        editor={ClassicEditor}
                        data={data.content}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);

                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          document.getElementById('content').value = data;
                          setContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() } } >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NotiCreate;
