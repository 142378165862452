import axios from "axios";
import Event from "module/event/Event";

// Withdraw class  
class Withdraw extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

}

export default Withdraw;