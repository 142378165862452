// 회원 생성 페이지

import React, { useEffect, useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { DropdownButton, Dropdown } from 'react-bootstrap'
import {
    API_MANAGER_CHANGE_PASSWORD,
    API_MANAGER_CRATE,
    API_MANAGER_DELETE,
    API_MANAGER_GET_ALL,
    API_MANAGER_UPDATE_TYPE
} from "../../const/api.const";
import axios from "axios";
import Session from "../../module/session/Session";

// module Manager 
import Manager from "module/manager/Manager";

import { MANAGER_PATH, ROOT_PATH } from "../../const/path.const";

function ManagerCreate() {

    //Event class
    let manager = new Manager(process.env.REACT_APP_MANAGERAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });

    const managerId = Session.getSession().managerId;
    const managerType = Session.getSession().managerType;
    const SUPER = "SUPER";

    const [option, setOption] = useState(1);

    // create manager
    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [password, setPassword] = useState("123qwe");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [newManagerType, setNewManagerType] = useState("DEVELOPER");

    // change password
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    // by SUPER
    const [targetManagerEmail, setTargetManagerEmail] = useState("");

    // change manager type
    const [targetManagerType, setTargetManagerType] = useState("DEVELOPER");

    const [managerList, setManagerList] = useState([]);

    useEffect(() => {
        getAllManager().then((res) => {
            setManagerList(res.data.data);
        })
    }, []);

    const sessionInfo = Session.getSession();

    // onClick

    const onClickDeleteHandler = (e) => {
        setOption(0);

        var title = document.getElementById("title").value;
        var content = document.getElementById("content").value;
      
        var data = {
          "title": title,
          "content": content
        };
       
        manager.delete(data);
    }

    const onClickCreateHandler = (e) => {
        setOption(1);

        var title = document.getElementById("title").value;
        var content = document.getElementById("content").value;
      
        var data = {
          "title": title,
          "content": content
        };
       
        manager.create(data);

    }

    // onChange

    const onChangeEmailHandler = (e) => {
        e.preventDefault();

        const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        const emailCurrent = e.target.value;
        setEmail(emailCurrent);

        if (!emailRegex.test(emailCurrent)) {
            setEmailMessage("이메일 형식이 올바르지 않습니다.");
        } else {
            setEmailMessage("올바른 이메일 형식입니다.");
        }
    }

    const onChangePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    }

    const onChangeFirstName = (e) => {
        e.preventDefault();
        setFirstName(e.target.value);
    }

    const onChangeLastName = (e) => {
        e.preventDefault();
        setLastName(e.target.value);
    }

    const onChangeDeleteManagerEmailHandler = (e) => {
        e.preventDefault();

        const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        const emailCurrent = e.target.value;
        setTargetManagerEmail(emailCurrent);

        if (!emailRegex.test(emailCurrent)) {
            setEmailMessage("이메일 형식이 올바르지 않습니다.");
        } else {
            setEmailMessage("올바른 이메일 형식입니다.");
        }
    }

    // onSelect
    const onSelectNewManagerHandler = (e) => {
        setNewManagerType(e);
    }

    // submit

    const onSubmitCreateManager = (e) => {
        e.preventDefault();
        if (managerType !== SUPER) {
            alert("관리자 생성은 SUPER 관리자만 가능합니다.");
            return;
        }
        createManager().then((res) => {
            if (res.data.code !== 200)
                alert("오류가 발생했습니다.");
            else
                window.location.replace(MANAGER_PATH);
        }).catch((err) => {
            alert("오류가 발생했습니다.");
            window.location.reload();
        })
    }

    const onSubmitDeleteManager = (e) => {
        e.preventDefault();
        if (managerType !== SUPER) {
            alert("관리자 삭제는 SUPER 관리자만 가능합니다.");
            return;
        }
        deleteManager().then((res) => {
            if (res.data.code !== 200)
                alert("오류가 발생했습니다.");
            else
                window.location.replace(MANAGER_PATH);
        }).catch((err) => {
            alert("오류가 발생했습니다.");
            // window.location.reload();
        })
    }

    // TODO: 관리자 타입 수정, 비밀번호 초기화, 비밀번호 변경 submit 만들기

    const onSubmitUpdateManagerType = (e) => {
        e.preventDefault(e);
        if (managerType !== SUPER) {
            alert("관리자 수정은 SUPER 관리자만 가능합니다.");
            return;
        }
        updateManagerType().then((res) => {
            if (res.data.code !== 200)
                alert("오류가 발생했습니다.");
            else
                window.location.replace(MANAGER_PATH);
        }).catch((err) => {
            alert("오류가 발생했습니다.");
            window.location.reload();
        })
    }

    // api
    const updateManagerType = async () => {
        return await axios.put(API_MANAGER_UPDATE_TYPE, {
            managerId,
            targetManagerEmail,
            targetManagerType
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        });
    }

    const resetPassword = async () => {
        return await axios.put(API_MANAGER_UPDATE_TYPE, {
            managerId,
            targetManagerEmail,
            password
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        });
    }

    const changePassword = async () => {
        return await axios.put(API_MANAGER_CHANGE_PASSWORD, {
            managerId,
            oldPassword,
            password: newPassword
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        });
    }

    const deleteManager = async () => {
        return await axios.delete(API_MANAGER_DELETE, {
            data: {
                superId: managerId,
                managerEmail: targetManagerEmail
            },
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        });
    }

    const createManager = async () => {
        return await axios.post(API_MANAGER_CRATE, {
            superId: managerId,
            managerType: newManagerType,
            email,
            password,
            firstName,
            lastName
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        });
    }

    const getAllManager = async () => {
        return await axios.get(API_MANAGER_GET_ALL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization_Access': sessionInfo.token
            }
        })
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12" lg="12">
                        <Card>
                            <CardHeader>
                                {option === 1 ? <h3 className="title d-inline">관리자 등록</h3> :
                                    <h3 className="title d-inline">관리자 삭제</h3>}
                                <Button className="btn btn-danger" style={{ float: "right" }}
                                    onClick={onClickDeleteHandler}>관리자
                                    삭제</Button>
                                <Button style={{ float: "right" }} onClick={onClickCreateHandler}>관리자 등록</Button>
                            </CardHeader>
                            {option === 1 ?
                                <CardBody>
                                    <Form onSubmit={onSubmitCreateManager}>
                                        <Row>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup>
                                                    <label htmlFor="exampleInputEmail1">
                                                        관리자 이메일
                                                    </label>
                                                    <Input placeholder="email" type="email"
                                                        onChange={onChangeEmailHandler} />
                                                    <div>
                                                        {email.length > 0 &&
                                                            <span className="emailConfirmSpan">{emailMessage}</span>}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup>
                                                    <label htmlFor="exampleInputEmail1">
                                                        관리자 비밀번호(기본 비밀번호 123qwe)
                                                    </label>
                                                    <Input defaultValue="123qwe" placeholder="email" type="password"
                                                        onChange={onChangePassword} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup>
                                                    <label>이름</label>
                                                    <Input
                                                        placeholder="First Name"
                                                        type="text"
                                                        onChange={onChangeFirstName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup>
                                                    <label>성</label>
                                                    <Input
                                                        placeholder="Last Name"
                                                        type="text"
                                                        onChange={onChangeLastName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-md-1" md="4">
                                                <div>
                                                    <DropdownButton id="dropdown-basic-button"
                                                        onSelect={onSelectNewManagerHandler}
                                                        title="관리자 타입">
                                                        <Dropdown.Item eventKey="SUPER">SUPER</Dropdown.Item>
                                                        <Dropdown.Item eventKey="DEVELOPER">DEVELOPER</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>

                                            </Col>
                                            <Col>
                                                <label>
                                                    {managerType}
                                                </label>
                                            </Col>
                                        </Row>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            등록하기
                                        </Button>
                                    </Form>
                                </CardBody>
                                :
                                <CardBody>
                                    <label>
                                        관리자 목록
                                    </label>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            이메일
                                        </Col>
                                        <Col className="pr-md-1" md="4">
                                            이름
                                        </Col>
                                        <Col className="pr-md-1" md="4">
                                            관리자 타입
                                        </Col>
                                    </Row>
                                    <Row>
                                        {managerList.map((manager) => {
                                            console.log(manager.managerId);
                                            return (
                                                <>
                                                    <Col className="pr-md-1" md="4">
                                                        <li key={manager.managerId}>{manager.email}</li>
                                                    </Col>
                                                    <Col className="pr-md-1" md="4">
                                                        <li>{manager.firstName}</li>
                                                    </Col>
                                                    <Col className="pr-md-1" md="4">
                                                        <li>{manager.managerType}</li>
                                                    </Col>
                                                </>
                                            )

                                        })}
                                    </Row>
                                    <Form onSubmit={onSubmitDeleteManager}>
                                        <Row>
                                            <Col className="pr-md-1" md="4">
                                                <FormGroup>
                                                    <label>관리자 이메일</label>
                                                    <Input
                                                        placeholder="example@email.com"
                                                        type="text"
                                                        onChange={onChangeDeleteManagerEmailHandler}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button className="btn-danger" color="primary" type="submit">
                                            삭제하기
                                        </Button>
                                    </Form>
                                </CardBody>}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ManagerCreate;
