/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// dao project class 생성
import DaoProject from "module/dao/DaoProject";


// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function ProjectList() {

    const [list, setList] = useState([]);
    const sessionInfo = Session.getSession();
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);

    let project = new DaoProject(process.env.REACT_APP_DAOPROJECTAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
        var result = await project.findAll(param);

        setList(result.data.data);
    }

    const setPagination = () => {

        var startPage = page - 1;
        var endPage = page + 1;

        if (page == 1) {
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<spanagination aria-label="Page navigation example"><spanaginationItem><spanaginationLink previous onClick={() => loadList(' + startPage + ') }/></PaginationItem>';

        for (var i = startPage; i <= endPage; i++) {
            pagination = '<spanaginationItem><spanaginationLink onClick={() => loadList(' + i + ') }/>' + i + '</PaginationItem>';
        }

        pagination += '<spanaginationItem><spanaginationLink next  onClick={() => loadList(' + endPage + ') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }

    //카테고리 기준 리스트 
    const getListByCategory = async (e) => {

        var result = await project.findCateAll(e.target.value, {
            pageSize: pageSize,
            currentPage: page - 1
        });

        setList(result.data.data);
    }

    //상태값 기준 리스트 
    const getListByState = async (e) => {

        if (e.target.value != '') {
            loadList(page);
        } else {
            var result = await project.findStateAll(e.target.value, {
                pageSize: pageSize,
                currentPage: page - 1
            });
        }

        setList(result.data.data);
    }

    //상태값 업데이트 하기 
    const updateState = async () => {

        var updateList = document.querySelectorAll(".progressId")

        console.log(updateList);

        if (updateList.length < 1) {
            alert('상태값을 수정할 항목을 체크해주세요.');
            return false;
        } else {

            updateList.forEach(async element => {
                if (element.checked && document.getElementById('changeState').value != '') {
                    var result = await project.update({
                        progressId: element.dataset.id,
                        progressId: element.value,
                        categoryCode: document.getElementById('projectCategory').value,
                        contestantState: document.getElementById('changeState').value
                    })

                    console.log(result);
                }
            });

            // alert('상태값을 변경하였습니다')
            window.location.reload();

        }

    }

    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".progressId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await project.remove(element.value)
                    console.log(result);
                }
            });
            // alert('상태값을 변경하였습니다')
            window.location.reload();

        }

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                                <Link to='/admin/dao/projectCreate'>
                                    <Button style={{ float: "right" }}>등록하기</Button>
                                </Link>
                                {/* <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button> */}
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <tbody>
                                            {list.map((project, ind) => {
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input defaultValue={project.progressId} data-id={project.progressId} type="checkbox" className="progressId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {project.fundingName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {project.fundingGenre}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {project.status}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {project.start_at} ~ {project.end_at}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {project.explain ?? '[설명]'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {project.created_at}
                                                        </span>
                                                    </td>
                                                    <td className="td-actions text-right">
                                                        <Link to={`/admin/dao/projectCreate?progressId=` + project.progressId}>
                                                            <Button
                                                                color="link"
                                                                id="tooltip457194718"
                                                                title=""
                                                                type="button"
                                                            >
                                                                <i className="tim-icons icon-pencil" />
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="pagination">

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ProjectList;
