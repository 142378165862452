/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Session from "module/session/Session";

import Agreement from "module/agreement/Agreement";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";

function AgreementCreate() {

  const sessionInfo = Session.getSession();
  const [type, setType] = useState('등록');
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const [selectType, setSelectType] = useState('');

  //Event class
  let agreement = new Agreement(process.env.REACT_APP_AGREEMENTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  // check get param about agreementId
  const queryParameters = new URLSearchParams(window.location.search)
  const agreementId = queryParameters.get("agreementId")

  useEffect(() => {

    if (agreementId) {
      setType('수정');
      getData();
    }

  }, []);

  const getData = async () => {
    var result = await agreement.findOne('agreementId='+agreementId)
    setSelectType(result.data.data.agreementType);
    await setData(result.data.data)
  }

  const handleType = (e) => {
    setSelectType(e.target.value);
  }

  //등록 혹은 수정
  const Submit = async () => {

    var title = document.getElementById("title").value;
    var contents = content;
    var agreementType = document.getElementById("agreementType").value;

    var data = {
      "title": title,
      "content": contents,
      "agreementType": agreementType
    };

    if (agreementId != '' && agreementId != null) {
      data = {
        "title": title,
        "content": contents,
        "agreementType": agreementType,
        "agreementId": agreementId,
      };
    }

    const result = agreementId != '' && agreementId != null ? await agreement.update(data) : await agreement.create(data);

    console.log(result);

    if (result.data.code == 200) {
      // alert('등록완료');
      window.location.href = '/'
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {agreementId != '' && agreementId != null ? <h3 className="Category">사이트약관 수정</h3> : <h3 className="Category">사이트약관 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>제목</label>
                      <Input
                        name="title"
                        id="title"
                        defaultValue={data.title}
                        placeholder="Title"
                        type="text"
                        className="fs-5"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 typeAdmin" md="6">
                    <FormGroup>
                      <Label for="exampleSelect">약관유형</Label>
                      <Input type="select" name="agreementType" id="agreementType" value={selectType} onChange={handleType}>
                        <option value="PERSONAL_INFO">개인정보처리방침</option>
                        <option value="PAYMENT">이용약관</option>
                        <option value="CARD">밈카드 이용약관</option>
                        <option value="REFUND">환불규정</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Row>
                    <Input type="hidden" name="content" id="content" value={data.content}>
                    </Input>
                    <Col md="8">
                      <CKEditor
                        name="content"
                        editor={ClassicEditor}
                        data={data.content}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);

                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          document.getElementById('content').value = data;
                          setContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AgreementCreate;
