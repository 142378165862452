/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// funding class 생성
import Polling from "module/polling/Polling";
// dao project class 생성
import DaoProject from "module/dao/DaoProject";
// funding class 생성
import Funding from "module/funding/Funding";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function ProjectCreate() {

  const [data, setData] = useState([]);
  const [fundingName, setFundingName] = useState('');
  const [fundingList, setFundingList] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search)
  const progressId = queryParameters.get("progressId")

  var pageSize = 1000;
  const [page, setPage] = useState(1);

  const sessionInfo = Session.getSession();

  const status = [
    { "EXPECTED": "예정" },
    { "PROCEEDING": "진행중" },
    { "HOLDING": "보류" },
    { "DONE": "완료" }
  ];

  let project = new DaoProject(process.env.REACT_APP_DAOPROJECTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  // 펀딩리스트를 통해서 펀딩 정보 저장 처리 추가 
  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });


  useEffect(() => {
    if (progressId != '' && progressId != null) {
      getData();
    }

    loadFundingList();
  }, [])

  const loadFundingList = async () => {
    // 펀딩 리스트도 가져오기 => 저장시 필요 
    var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
    var result = await funding.findAll(param);

    setFundingList(result.data.data);
  }

  const getData = async () => {
    var result = await project.findOne('progressId=' + progressId)
    await setData(result.data.data)
    await setFundingName(result.data.data.fundingName)
  }

  const changeFundingName = async() => {
    var target = document.getElementById("fundingId");
    setFundingName(target.options[target.selectedIndex].text);
  }

  //등록 혹은 수정
  const Submit = async () => {

    var fundingId = document.getElementById("fundingId").value;
    var status = document.getElementById("status").value;
    var fundingGenre = document.getElementById("fundingGenre").value;
    var explain = document.getElementById("explain").value;
    var startAt = document.getElementById("startAt").value;
    var endAt = document.getElementById("endAt").value;

    var data = {
      "fundingId": fundingId,
      "fundingName": fundingName,
      "status": status,
      "fundingGenre": fundingGenre,
      "explain": explain,
      "start_at": startAt,
      "end_at": endAt,
      "writerId" : sessionStorage.getItem('userId')
    };


    if (progressId != '' && progressId != null) {
      data = {
        "fundingId": fundingId,
        "fundingName": fundingName,
        "status": status,
        "fundingGenre": fundingGenre,
        "explain": explain,
        "start_at": startAt,
        "end_at": endAt,
        "writerId" : sessionStorage.getItem('userId'),
        "progressId": progressId,
      };
    }

    const result = progressId != '' && progressId != null ? await project.update(data) : await project.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/dao/projectList';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {progressId != '' && progressId != null ? <h3 className="Category">DAO 프로젝트 진행상황 수정</h3> : <h3 className="Category">DAO 프로젝트 진행상황 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>프로젝트 정보</label>
                      <Input type="select" name="fundingId" id="fundingId" className="mt-3" onChange={changeFundingName}>
                      <option value=''>프로젝트를 선택해 주세요.</option>
                        {fundingList && fundingList.map((prop, key) => {
                          var selected = '';
                          if (prop.fundingId == data.fundingId) {
                            selected = 'selected'
                          }
                          return <option value={prop ? prop.fundingId : ''} selected={selected}>{prop.synopsisTitle ?? ''}</option>
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>프로젝트 진행상태</label>
                      <Input type="select" name="status" id="status" className="mt-3">
                        <option value=''>진행상태를 선택해 주세요.</option>
                        <option value="EXPECTED" selected={data && data.status && data.status == 'EXPECTED' ? 'selected' : ''}>제작준비중</option>
                        <option value="PROCEEDING" selected={data && data.status && data.status == 'PROCEEDING' ? 'selected' : ''}>제작중</option>
                        <option value="HOLDING" selected={data && data.status && data.status == 'HOLDING' ? 'selected' : ''}>제작보류</option>
                        <option value="DONE" selected={data && data.status && data.status == 'DONE' ? 'selected' : ''}>제작완료</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>프로젝트 장르</label>
                      <textarea
                        name="fundingGenre"
                        defaultValue={data.fundingGenre ?? ''}
                        placeholder="장르를 입력해주세요.(여러개일 경우, 구분자 , 를 사용하여 입력해주세요.)"
                        className="fs-5 w-100"
                        id="fundingGenre">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>설명</label>
                      <textarea
                        name="explain"
                        defaultValue={data.explain ?? ''}
                        placeholder="프로젝트 진행에 관한 설명을 작성해 주세요."
                        className="fs-5 w-100"
                        id="explain">
                      </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시작일</label>
                      <Input
                        name="startAt"
                        defaultValue={data.start_at ?? ''}
                        placeholder="startAt"
                        type="date"
                        className="fs-5"
                        id="startAt"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>종료일</label>
                      <Input
                        name="endAt"
                        defaultValue={data.end_at ?? ''}
                        placeholder="endAt"
                        type="date"
                        className="fs-5"
                        id="endAt"
                      />
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ProjectCreate;
