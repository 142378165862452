/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {escapeHtml,unescapeHtml} from "../../../lib/global_function";

// control session
import Session from "module/session/Session";
// mobule notice 
import DaoProjectNotice from "module/dao/DaoProjectNotice";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function NotiCreate() {

  const [data,setData] = useState([]);
  const [content,setContent] = useState([]);
  const [imgSrc, setImgSrc] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const daoNoticeId = queryParameters.get("daoNoticeId")

  const sessionInfo = Session.getSession();


  //Event class
  let notice = new DaoProjectNotice(process.env.REACT_APP_DAONOTICEAPI,{
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(()=>{
    if(daoNoticeId != '' && daoNoticeId != null){
        getData();
    }
  },[])

  const getData = async() => {
    var result = await notice.findOne('daoNoticeId='+daoNoticeId)
    await setData(result.data.data)
  }

  const customUploadAdapter = (loader) => { // (2)
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(async (file) => {
            // data.append("name", file.name);
            data.append("media", file);

            console.log(file);

            var headers = {
              'Content-Type': 'application/x-www-form-urlencoded'
            }

            var result = await notice.uploadFile(daoNoticeId, data, headers);

            console.log(result);

            if (result.data.codeMsg == 'SUCCESS') {
              // 이미지 업로드 완료
              setImgSrc(result.data.data);

              resolve({
                default: `${result.data.data}`
              });
            } else {
              alert('이미지 업로드에 실패하였습니다. 개발자에게 문의해 주세요.');
            }

          })
        })
      }
    }
  }

  function uploadPlugin(editor) { // (3)
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    }
  }

  //등록 혹은 수정
  const Submit = async () => {

    var title = document.getElementById("title").value;
    var contents = content;
  
    var data = {
      "title": title,
      "content": contents
    };

    if(daoNoticeId != '' && daoNoticeId != null) {
      data = {
        "title": title,
        "content": contents,
        "noticeId": daoNoticeId,
      };
    }

    const result =  daoNoticeId != '' && daoNoticeId != null ? await notice.update(data) : await notice.create(data);

    console.log(result);

    if(result.data.code == 200) {
      //  alert('등록완료');
       window.location.href = '/admin/dao/noticeList';
    } else {
       alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                 {daoNoticeId != '' && daoNoticeId != null ? <h3 className="Category">DAO 공지사항 수정</h3> : <h3 className="Category">DAO 공지사항 등록</h3>}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>제목</label>
                      <Input
                        name="title"
                        defaultValue={data.title}
                        placeholder="Title"
                        type="text"
                        className="fs-5"
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <Input type="hidden" name="content" id="content" value={data.content}>
                    </Input>
                    <Col md="8">  
                      <CKEditor
                        name="content"
                        editor={ClassicEditor}
                        data={unescapeHtml(data.content)}
                        config={{ // (4)
                          extraPlugins: [uploadPlugin]
                        }}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);

                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          document.getElementById('content').value = data;
                          setContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() } } >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NotiCreate;
