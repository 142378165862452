/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NICE_BANK_CODE } from "../../lib/global_variable";

// control session
import Session from "module/session/Session";
// Budget class 생성
import Budget from "module/budget/Budget";
// Funding class 생성
import Funding from "module/funding/Funding";
// User class 생성

import Platform from "module/platform/Platform";

import Paginations from "../../components/Pagination";

// reactstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  PaginationItem,
  PaginationLink,
  PaginationComponent,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

function BudgetList() {
  const [list, setList] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [reload, setReload] = useState(false);
  const sessionInfo = Session.getSession();
  const [budgetListCnt, setBudgetListCnt] = useState(0); //all budget list
  var pageSize = 100;

  //이미지 확장자 여부 확인
  var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [fundingData, setFundingData] = useState([]);
  const [platformData, setPlatformData] = useState([]);

  const offset = (page - 1) * pageSize; // 시작점과 끝점을 구하는 offset

  const state = {
    O1: "신청",
    CD: "취소",
    HD: "승인보류",
    OD: "승인완료"
  };

  let budget = new Budget(process.env.REACT_APP_BUDGETAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  let funding = new Funding(process.env.REACT_APP_FUNDINGAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  //platform class
  let platform = new Platform(process.env.REACT_APP_PLATFORMAPI, {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization_Access: sessionInfo.token
  });

  const now = new Date();
  const formattedDateTime = now.toISOString().replace("Z", "");

  useEffect(() => {
    loadList(page);
    setPagination();
    getFundingData();
    getPlatformData();
  }, []);

  useEffect(() => {
    loadList(page);
  }, [page]);

  useEffect(() => {
    setPagination();
  }, [list]);

  const loadList = async (page) => {
    var param = "?pageSize=" + pageSize + "&currentPage=" + (page - 1);

    if (searchType != "" && searchKeyword != "") {
      switch (searchType) {
        case "nickname":
          param += "&nickname=" + searchKeyword;
          break;
        case "email":
          param += "&email=" + searchKeyword;
          break;
      }
    }

    if (searchStatus != "") {
      param += "&status=" + searchStatus;
    }

    if (searchOrder != "") {
      param += "&order=" + searchOrder;
    }

    var result = await budget.findAll(param);

    setPage(page);

    if (result.data.data && result.data.data.length > 0) {
      var budgetDatas = result.data.data;

      setList(budgetDatas);
      setBudgetListCnt(budgetDatas.length ?? 0);

      setPagination();
    } else {
      setList([]);
      setBudgetListCnt(0);
    }
  };

  const setPagination = () => {
    setStartPage(page);
    setEndPage(
      budgetListCnt % pageSize > 0
        ? parseInt(budgetListCnt / pageSize) + 1
        : parseInt(budgetListCnt / pageSize)
    );

    if (page == 1) {
      setStartPage(1);
    }
  };

  const getPlatformData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await platform.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].fundingIncomePlatformId) {
          result_arr.push({
            value: result.data.data[i].fundingIncomePlatformId,
            label: result.data.data[i].name
          });
        }
      }
    }

    await setPlatformData(result_arr);
  };

  const getFundingData = async (where) => {
    var param = "?pageSize=5000&currentPage=0";
    var result = await funding.findAll(param);

    var result_arr = [];

    if (result && result.data) {
      for (var i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].pollingResultId) {
          result_arr.push({
            value: result.data.data[i].pollingResultId,
            label: result.data.data[i].synopsisTitle
          });
        }
      }
    }

    await setFundingData(result_arr);
  };

  //상태값 업데이트 하기
  const updateState = async () => {
    var updateList = document.querySelectorAll(".requestId");

    console.log(updateList);

    if (updateList.length < 1) {
      alert("상태값을 수정할 항목을 체크해주세요.");
      return false;
    } else {
      updateList.forEach(async (element) => {
        if (
          element.checked &&
          document.getElementById("changeState").value != ""
        ) {
          var created_at = document.getElementById(
            "createdAt_" + element.value
          ).value;

          if (created_at.length == 22) {
            created_at = created_at + "1";
          }

          var result = await budget.update("?requestId=" + element.value, {
            requestId: element.value,
            status: document.getElementById("changeState").value
          });

          console.log(result);
        }
      });

      alert("상태값을 변경하였습니다");
      window.location.reload();
    }
  };

  // 체크 리스트 삭제 처리
  const deleteList = async () => {
    // 모든 .requestId 요소들을 가져옵니다.
    const deleteList = Array.from(document.querySelectorAll(".requestId"));
    const checkedItems = deleteList.filter((element) => element.checked);

    console.log(checkedItems);

    if (checkedItems.length < 1) {
      alert("삭제할 항목을 체크해주세요.");
      return false;
    } else {
      for (const element of checkedItems) {
        // element가 input 요소가 맞는지 확인하고, value 값을 올바르게 가져옵니다.
        const fundingIncomeDetailId = element.value;

        if (!fundingIncomeDetailId) {
          console.error("삭제할 ID가 없습니다:", element);
          continue;
        }

        // 삭제 요청을 보냅니다.
        const result = await budget.remove("?fundingIncomeDetailId="+fundingIncomeDetailId);
        console.log(result);
      }

      alert("리스트를 삭제하였습니다.");
      window.location.reload();
    }
  };

  // 정렬기능추가
  const setOrderType = async (e) => {
    if (searchOrder == "") {
      setSearchOrder("asc");
    } else {
      setSearchOrder("");
    }

    loadList(page);
  };

  const handleChange = async (e) => {
    if (e.target.name == "searchStatus") {
      setSearchStatus(e.target.value);
    } else if (e.target.name == "searchType") {
      setSearchType(e.target.value);
    } else if (e.target.name == "searchKeyword") {
      setSearchKeyword(e.target.value);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12">
            <Card className="card-tasks">
              <CardHeader>
                {/* <div className="d-inline-flex">
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <Input type="select" name="searchType" id="searchType" value={searchType} onChange={handleChange}>
                                                    <option value="">검색타입</option>
                                                    <option value="email">이메일</option>
                                                    <option value="nickname">닉네임</option>
                                                </Input>
                                            </div>
                                            <input type="text" id="searchKeyword" name="searchKeyword" value={searchKeyword} class="form-control" aria-label="검색어를 입력해주세요." onChange={handleChange}></input>
                                            <Button className="btn btn-primary" onClick={() => { loadList(page) }}>검색하기</Button>
                                        </div>
                                    </FormGroup>
                                </div> */}
                <Link to="/admin/budgetCreate">
                  <Button style={{ float: "right" }}>등록</Button>
                </Link>
                <Button
                  className="btn btn-danger"
                  style={{ float: "right" }}
                  onClick={deleteList}
                >
                  삭제하기
                </Button>
              </CardHeader>
              <CardBody>
                <div
                  className="table-full-width table-responsive"
                  style={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                  <Table>
                    <colgroup>
                      <col width="5%" />
                      <col width="7%" />
                      <col width="7%" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <th></th>
                      <th>년</th>
                      <th>월</th>
                      <th>플랫폼정보</th>
                      <th>입금 대상 작품</th>
                      <th>해당 작품의 입금건 총액</th>
                      <th>별도 추가 기입사항</th>
                      <th></th>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((budgetData, ind) => {
                          return (
                            <tr>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                 {budgetData.year ?? "2000"}년
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                 {budgetData.month ?? "1"}월
                                </span>
                              </td>
                              <td className="w-5">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      value={
                                        budgetData?.fundingIncomeDetailId ?? ""
                                      }
                                      type="checkbox"
                                      className="requestId"
                                    />
                                    <span className="form-check-sign">
                                      <span className="check" />
                                    </span>
                                  </Label>
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {platformData && platformData.length > 0
                                    ? platformData.find(
                                        (platform) =>
                                          platform.value ===
                                          budgetData.PlatformId
                                      )?.label || "No Platform Found"
                                    : "No Platforms Available"}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {fundingData &&
                                    fundingData.map((funding, key) => {
                                      if (
                                        funding.value == budgetData.pollingResultId
                                      ) {
                                        return funding.label;
                                      }
                                    })}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {budgetData.shareAmount ?? ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="fs-5"
                                  style={{ display: "inline-block" }}
                                >
                                  {budgetData.comment ?? ""}
                                </span>
                              </td>
                              <td className="td-actions text-right">
                                <Link
                                  to={
                                    `/admin/budgetCreate?detailId=` +
                                    budgetData.fundingIncomeDetailId
                                  }
                                >
                                  <Button
                                    color="link"
                                    id="tooltip457194718"
                                    title=""
                                    type="button"
                                  >
                                    <i className="tim-icons icon-pencil" />
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <div
            className="pagination"
            id="pagination"
            style={{
              position: "absolute",
              left: "50%",
              bottom: "0%",
              tranform: "translate(-50%,0)"
            }}
          >
            <Paginations
              limit={pageSize}
              page={page}
              totalPosts={budgetListCnt}
              setPage={setPage}
            />
          </div>
        </Row>
      </div>
    </>
  );
}

export default BudgetList;
