/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// project class 생성
import PollingResult from "module/polling/PollingResult";


// reactstrap components
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

function PollingResultList() {

    const [list, setList] = useState([]);
    const sessionInfo = Session.getSession();
    var pageSize = 100;

    //이미지 확장자 여부 확인 
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;

    const [page, setPage] = useState(1);

    const state = {
        "PENDING": "승인중",
        "RECEIVED": "승인완료",
        "REVIEWING": "심사중",
        "ACCEPTED": "심사완료",
        "POLLING": "투표중",
        "SELECTED": "투표선정",
        "DROPPED": "투표탈락"
    };

    const category = {
        "P01_01_01": "공모전"
    };

    //polling class
    let pollingResult = new PollingResult(process.env.REACT_APP_POLLINGRESULTAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
    });


    useEffect(() => {
        loadList(page);
        setPagination();
    }, [])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
        var result = await pollingResult.findAll(param);

        setList(result.data.data);
    }

    const setPagination = () => {

        var startPage = page - 1;
        var endPage = page + 1;

        if (page == 1) {
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<spanagination aria-label="Page navigation example"><spanaginationItem><spanaginationLink previous onClick={() => loadList(' + startPage + ') }/></PaginationItem>';

        for (var i = startPage; i <= endPage; i++) {
            pagination = '<spanaginationItem><spanaginationLink onClick={() => loadList(' + i + ') }/>' + i + '</PaginationItem>';
        }

        pagination += '<spanaginationItem><spanaginationLink next  onClick={() => loadList(' + endPage + ') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }

    //카테고리 기준 리스트 
    const getListByCategory = async (e) => {

        var result = await pollingResult.findCateAll(e.target.value, {
            pageSize: pageSize,
            currentPage: page - 1
        });

        setList(result.data.data);
    }


    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".pollingResultId")

        console.log(deleteList);

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if (element.checked) {
                    var result = await pollingResult.remove(element.value)
                    console.log(result);
                }
            });

            // alert('상태값을 변경하였습니다')
            // window.location.reload();

        }

    }

    const autoCreate = async (e) => {
        var result = await pollingResult.createAuto(e.target.value, {});
        window.location.reload();
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <div className="d-inline-flex">
                                    <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                        <Label check>
                                            카테고리
                                            <Input type="select" name="projectCategory" id="projectCategory" className="mt-3" onChange={getListByCategory}>
                                                <option value="P01_01_01">공모전 프로젝트</option>
                                                {/* <option value="P01_01_02">개인미디어 프로젝트</option> */}
                                            </Input>
                                        </Label>
                                    </FormGroup>

                                </div>
                                <Link to='/admin/pollingResultCreate'>
                                    <Button style={{ float: "right" }}>투표결과 등록하기</Button>
                                </Link>
                                <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
                                    <Label check>
                                        투표결과 등록(AUTO)
                                        <Input type="select" name="pollingResult" id="pollingResult" className="mt-3" onChange={autoCreate}>
                                            <option value="P01_01_01">공모전 프로젝트</option>
                                            <option value="P01_01_02">성인웹툰 프로젝트</option>
                                        </Input>
                                    </Label>
                                </FormGroup>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    <Table>
                                        <tbody>
                                            {list.map((pollingResult, ind) => {
                                                return <tr>
                                                    <td className="w-5">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input defaultValue={pollingResult.pollingResultId} data-id={pollingResult.contestantId} type="checkbox" className="contestantId" />
                                                                <span className="form-check-sign">
                                                                    <span className="check" />
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {category[pollingResult.categoryCode]}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {pollingResult.writerName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block' }}>
                                                            {pollingResult.synopsisTitle}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {pollingResult.description}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="fs-5" style={{ display: 'inline-block', float: "right" }}>
                                                            {pollingResult.genres}
                                                        </span>
                                                    </td>
                                                    <td className="td-actions text-right">
                                                        <Link to={`/admin/fundingCreate?pollingResultId=` + pollingResult.pollingResultId}>
                                                            <Button
                                                                color="link"
                                                                id="tooltip457194718"
                                                                title=""
                                                                type="button"
                                                            >
                                                                <i className="tim-icons icon-pencil" />
                                                            </Button>
                                                        </Link>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip457194718"
                                                            placement="right"
                                                        >
                                                            수정하기
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="pagination">

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default PollingResultList;
