/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
// mobule polling 
import PollingResult from "module/polling/PollingResult";
// module project
import Project from "module/project/Project";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function PollingResultCreate() {

  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState('');
  const [contestantId, setContestantId] = useState('');

  var pageSize = 1000;
  var page = 1;

  const queryParameters = new URLSearchParams(window.location.search)
  const pollingResultId = queryParameters.get("pollingResultId")

  const sessionInfo = Session.getSession();


  //polling class
  let pollingResult = new PollingResult(process.env.REACT_APP_POLLINGRESULTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  useEffect(() => {
    if (pollingResultId != '' && pollingResultId != null) {
      getData();
    }
  }, [])

  const getData = async () => {
   var result = await pollingResult.findOne('pollingResultId=' + pollingResultId)
   await setData(result.data.data)
  }

  //등록 혹은 수정
  const Submit = async () => {

    var categoryCode = document.getElementById("categoryCode").value;
    var description = document.getElementById("description").value ?? '';
    var genres = document.getElementById("genres").value ?? '';
    var synopsisTitle = document.getElementById("synopsisTitle").value ?? '';
    var writerName = document.getElementById("writerName").value ?? '';

    var data = {
      "categoryCode": categoryCode,
      "description": description,
      "genres": genres,
      "pollingResultId": "",
      "synopsisTitle": synopsisTitle,
      "writerName": writerName
    };


    if (pollingResultId != '' && pollingResultId != null) {
      data =  {
        "categoryCode": categoryCode,
        "description": description,
        "genres": genres,
        "pollingResultId": pollingResultId,
        "synopsisTitle": synopsisTitle,
        "writerName": writerName
      };
    }

    const result = pollingResultId != '' && pollingResultId != null ? await pollingResult.update(data) : await pollingResult.create(data);

    if (result.data.code == 200) {
      window.location.href = '/admin/funding';
    } else {
      alert('등록실패');
    }

  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                {pollingResultId != '' && pollingResultId != null ? <h3 className="Category">투표결과 수정</h3> : <h3 className="Category">투표결과 등록</h3>}
                {console.log(data)}
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>카테고리</label>
                      <Input
                        name="categoryCode"
                        defaultValue={data.categoryCode ?? 'P01_01_01'}
                        placeholder="categoryCode"
                        type="text"
                        className="fs-5"
                        id="categoryCode"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>작가명</label>
                      <Input
                        name="writerName"
                        defaultValue={data.writerName ?? ''}
                        placeholder="작가명을 입력해 주세요"
                        type="text"
                        className="fs-5"
                        id="writerName"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 작품명</label>
                      <Input
                        name="synopsisTitle"
                        defaultValue={data.synopsisTitle ?? ''}
                        placeholder="작품명을 입력해 주세요"
                        type="text"
                        className="fs-5"
                        id="synopsisTitle"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 설명</label>
                      <textarea
                        name="description"
                        defaultValue={data.description ?? ''}
                        placeholder="시놉시스 요약글/설명글을 작성해 주세요."
                        className="fs-5 w-100"
                        id="description">
                        </textarea>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1 titleAdmin" md="6">
                    <FormGroup>
                      <label>시놉시스 장르</label>
                      <textarea
                        name="genres"
                        defaultValue={data.genres ?? ''}
                        placeholder="시놉시스 장르 입력해 주세요.(구분자 ,)"
                        className="fs-5 w-100"
                        id="genres">
                        </textarea>
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button" onClick={() => { Submit() }} >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PollingResultCreate;
