import axios from "axios";
import Event from "module/event/Event";

// Agreement class  
class Agreement extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }
}

export default Agreement;