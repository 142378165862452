
import React from "react";
import axios from "axios";
import { useEffect, useState, Suspense } from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";

import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    PaginationComponent,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";


function Paginations({ page, totalPosts, limit, setPage }) {

    const numPages = parseInt(totalPosts / limit) + 1;
    const [currPage, setCurrPage] = useState(page)
    let firstNum = currPage - (currPage % 5) + 1
    let lastNum = currPage - (currPage % 5) + 5
    //console.log({"currPage is":currPage, "firsNum is" : firstNum, "page is" : page})

    return (
        <Pagination>
            <PaginationItem>
                <PaginationLink
                    onClick={() => { setPage(page - 1); setCurrPage(page - 2); }}
                    disabled={page === 1}>
                </PaginationLink>
            </PaginationItem>
            <PaginationItem>
                <PaginationLink
                    onClick={() => setPage(firstNum)}
                    aria-current={page === firstNum ? "page" : null}>
                    {firstNum}
                </PaginationLink>
            </PaginationItem>
            {Array((numPages > 4 ? 4 : numPages - 1)).fill().map((_, i) => {
                if (i <= 2) {
                    return (
                        <PaginationItem>
                            <PaginationLink
                                border="true"
                                key={i + 1}
                                onClick={() => { setPage(firstNum + 1 + i) }}
                                aria-current={page === firstNum + 1 + i ? "page" : null}>
                                {firstNum + 1 + i}
                            </PaginationLink>
                        </PaginationItem>
                    )
                }
                else if (i >= 3) {
                    return (
                        <PaginationItem>
                            <PaginationLink
                                border="true"
                                key={i + 1}
                                onClick={() => setPage(lastNum)}
                                aria-current={page === lastNum ? "page" : null}>
                                {lastNum}
                            </PaginationLink>
                        </PaginationItem>
                    )
                }
            })}
            <PaginationItem>
                <PaginationLink
                    onClick={() => { setPage(page + 1); setCurrPage(page); }}
                    disabled={page === numPages}>
                    &gt;
                </PaginationLink>
            </PaginationItem>
        </Pagination>
    )
}



export default Paginations;