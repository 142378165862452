/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* create notice list

*/
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NICE_BANK_CODE } from "../../lib/global_variable"
// control session
import Session from "module/session/Session";
// module pay 
import Settlement from "module/settlement/Settlement";
// Funding class 생성
import Funding from "module/funding/Funding";
// Ticket class 생성
import Ticket from "module/ticket/Ticket";
// User class 생성
import User from "module/user/User";

// reactstrap components
import {
  Button,
  Card,
  Label,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

function SettlementView() {

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState('');
  const [pollingResultId, setContestantId] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id")

  const sessionInfo = Session.getSession();

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  // const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const formattedDateTime = now.toISOString().replace('Z', '');



  //settlement class
  let settlement = new Settlement(process.env.REACT_APP_SETTLEMENTAPI, {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization_Access': sessionInfo.token
  });

  const state = {
    "O1": "정산신청",
    "CD": "정산취소",
    "HD": "정산보류",
    "OD": "정산완료",
  };

  useEffect(() => {
    if (id != '' && id != null) {
      getData();
    }
  }, [])


  const getData = async () => {
    var result = await settlement.findOne('id=' + id)

    if (result.data.data) {
      setData(result.data.data);
    }

  }

  //상태값 업데이트 하기 
  const updateState = async () => {
    var created_at = document.getElementById('createdAt_' + id).value;

    if (created_at.length == 22) {
      created_at = created_at + '1';
    }

    var result = await settlement.update('?id=' + id, {
      id: id,
      status: document.getElementById('changeState').value
    })

    console.log(result);

    if (result.data.code == 200) {
      alert('상태값 변경을 완료 하였습니다.');
      window.location.reload();
    } else {
      alert('상태값 변경에 실패 하였습니다.');
    }
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" lg="12">
            <Card>
              <CardHeader>
                <h3 className="Category">리워드 신청 정보</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>신청금액</label>
                    <p>{data?.amount ?? 0}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>신청상태</label>
                    <p>{data.status ? state[data.status] : '정산신청'}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>신청자 아이디</label>
                    <p>{data.user ? data.user.email : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>신청자 닉네임</label>
                    <p>{data.user ? data.user.nickName : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>은행명</label>
                    <p>
                      {(data.user && data.user.bankName) &&
                        NICE_BANK_CODE.map((option, index) => {
                          if (option.value === data.user.bankName) {
                            return <p>{option.label}</p>
                          }
                        })}
                    </p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>계좌번호</label>
                    <p>{data.user ? (data.user.bankAccount ?? '없음') : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>예금주명</label>
                    <p>{data.user ? (data.user.accountHolderName ?? '없음') : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>신청일</label>
                    <p>{data.createdAt ? data.createdAt : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>승인일</label>
                    <p>{data.confirmationAt ? data.confirmationAt : ''}</p>
                  </Col>
                  <Col className="pr-md-1 titleAdmin d-inline-block" md="6">
                    <label>삭제일</label>
                    <p>{data.deletedAt ? data.deletedAt : ''}</p>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <span>
          선택된 신청
          <FormGroup check style={{ display: "inline-block", marginRight: "5px" }}>
            <Label check>
              <Input type="select" name="changeState" id="changeState" onChange={updateState}>
                <option value="">신청상태</option>
                <option value="O1">정산신청</option>
                <option value="CD">정산취소</option>
                <option value="HD">정산보류</option>
                <option value="OD">정산완료</option>
              </Input>
            </Label>
          </FormGroup>
          으로 변경
        </span>
      </div>
    </>
  );
}

export default SettlementView;
