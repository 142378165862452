/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim 

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

* show notice list

*/
import React from "react";
import axios from "axios";  
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// control session
import Session from "module/session/Session";
//import module Agreement
import Agreement from "module/agreement/Agreement";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";

function AgreementList() {
    const sessionInfo = Session.getSession();

    const [list,setList] = useState([]);
    const [page,setPage] = useState(1);

    var pageSize = 10;

    let agreement = new Agreement(process.env.REACT_APP_AGREEMENTAPI,{
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization_Access': sessionInfo.token
      });
    
      
    useEffect(() => {
        loadList(page);
        setPagination();
    },[])

    const loadList = async (page) => {
        var param = '?pageSize='+pageSize+'&currentPage='+(page - 1);
        var result = await agreement.findAll(param);
        setList(result.data.data);
    }


    const setPagination = () => {
        
        var startPage = page -1 ;
        var endPage = page + 1;

        if(page == 1){
            startPage = 1;
            endPage = page + 2;
        }

        var pagination = '<Pagination aria-label="Page navigation example"><PaginationItem><PaginationLink previous onClick={() => loadList('+startPage+') }/></PaginationItem>';
        
        for(var i = startPage; i <= endPage ; i++ ){
            pagination = '<PaginationItem><PaginationLink onClick={() => loadList('+i+') }/>'+i+'</PaginationItem>';
        }
        
        pagination += '<PaginationItem><PaginationLink next  onClick={() => loadList('+endPage+') }/></PaginationItem></Pagination>';


        document.getElementsByClassName('pagination').html = pagination;
    }
    
    // 체크 리스트 삭제 처리 
    const deleteList = async () => {

        var deleteList = document.querySelectorAll(".agreementId")

        if (deleteList.length < 1) {
            alert('삭제할 항목을 체크해주세요.');
            return false;
        } else {

            deleteList.forEach(async element => {
                if(element.checked){
                    var result = await agreement.remove(element.value)
                    console.log(result);
                }
            });

            window.location.reload();
        }

    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-tasks">
                            <CardHeader>
                                <h6 className="title d-inline">선택된 데이터(5)</h6>
                                <Button className="btn btn-danger" style={{ float: "right" }} onClick={deleteList}>삭제하기</Button>
                                <Link to='/admin/agreementCreate'>
                                    <Button style={{ float: "right" }}>등록하기</Button>
                                </Link>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                <Table>
                                <tbody>
                                        {list.map((agreement,ind) => {
                                                return <tr key={ind}>
                                                <td className="w-10">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                defaultValue={agreement.agreementId}
                                                                className="agreementId"
                                                                type="checkbox"
                                                            />
                                                            <span className="form-check-sign">
                                                                <span className="check" />
                                                            </span>
                                                        </Label>
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <p className="title fs-3">{agreement.title}</p>
                                                    <div>
                                                        <p className="fs-5" style={{ display: 'inline-block' }}>
                                                            {agreement.content}
                                                        </p>
                                                        <p className="fs-6" style={{ display: 'inline-block', float: "right" }}>
                                                            {agreement.createdAt}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="td-actions text-right">
                                                    <Link to={`/admin/agreementCreate?agreementId=`+agreement.agreementId}>
                                                        <Button
                                                            color="link"
                                                            id="tooltip457194718"
                                                            title=""
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-pencil" />
                                                        </Button>
                                                    </Link>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip457194718"
                                                        placement="right"
                                                    >
                                                        Edit Task
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AgreementList;
